import operationsHistory from '../dicts/operationsHistory';
import profile from '../dicts/profile';
import parking from '../dicts/parking';
import operation from '../dicts/operation';
import filter from '../dicts/filter';
import loginForm from '../dicts/loginForm';
import registrationForm from '../dicts/registrationForm';
import abonements from '../dicts/abonements';
import reservation from '../dicts/reservation';
import notFound from '../dicts/notFound';
import restorePassword from '../dicts/restorePassword';
import cloudPayments from '../dicts/cloudPayments';
import errors from '../dicts/errors';
import exportTr from '../dicts/export';
import dates from '../dicts/dates';
import penalty from '../dicts/penalty';

const ru = {
  phone: { label: 'Телефон' },
  sideMenu: {
    parkingMap: 'Карта парковок',
  },
  auth: {
    registration: 'Регистрация',
    login: 'Вход',
    logout: 'Выйти',
    tokenExpired: 'Срок сессии истек. Пожалуйста войдите в аккаунт снова.',
  },
  changeCity: {
    selectCityFromList: 'Выберите свой город из списка.',
    confirmButton: 'Подтвердить',
    inputPlaceholder: 'Город',
    changeCity: 'Выбрать город',
  },
  header: {
    logoTitle: {
      belgorod: 'Белгорода',
      full: 'Парковочное пространство Севастополя',
      parkingSpace: 'Парковочное пространство',
      sevastopol: 'Севастополя',
      voronezh: 'Воронежа',
      kazan: 'Казани',
      spb: 'Ленинграда',
      krasnogorsk: 'Красногорска',
      rostov: 'Ростов-на-Дону',
      tyumen: 'Тюменские парковки',
      cheboksary: 'Чебоксар',
      tver: 'Твери',
      penza: 'Пенза',
      novorossiysk: 'Новороссийск',
      yoshkarola: 'Йошкар-Ола',
      yalta: 'Ялты',
      vlc: 'Владивосток',
      mo: 'Московской области',
      anapa: 'Анапы',
    },
    balance: {
      balance: 'Баланс',
      refill: 'Пополнить',
    },
    parkingInfo: {
      zone: 'Зона парковки',
      number: 'Номер',
      parking: 'Парковки',
      allParking: 'Все парковки',
      startParking: 'Начать парковку',
      infoNotFound: 'В данный момент вы нигде не припаркованы',
      left: 'Осталось',
      from: 'С',
      to: 'До',
    },
  },
  footer: {
    ourApp: 'Наши приложения',
  },
  profile: {
    ...profile.ru,
  },
  parking: {
    ...parking.ru,
  },
  map: {
    searchField: 'Введите адрес',
  },
  filter: {
    ...filter.ru,
  },
  reservation: {
    ...reservation.ru,
  },
  operations: {
    ...operation.ru,
  },
  operationsHistory: {
    ...operationsHistory.ru,
  },
  loginForm: {
    ...loginForm.ru,
  },
  registrationForm: {
    ...registrationForm.ru,
  },
  abonements: {
    ...abonements.ru,
  },
  notFound: {
    ...notFound.ru,
  },
  restorePassword: {
    ...restorePassword.ru,
  },
  cloudPayments: {
    ...cloudPayments.ru,
  },
  errors: {
    ...errors.ru,
  },
  export: {
    ...exportTr.ru,
  },
  dates: {
    ...dates.ru,
  },
  smsCommands: {
    title: 'Смс-команды',
    sendTo: 'Смс с командой отправлять на номер',
  },
  penalty: {
    ...penalty.ru,
  },
  feedback: {
    title: 'Обратная связь',
  },
};

export default ru;
