import PARK_TYPES from '../../constants/parkTypes';

const reservationEng = {
  head: {
    zone: 'Zone'
  },
  parking: {
    places: {
      from: 'from',
      placesFree: 'vacant spaces',
      totalPlaces: 'Total places',
      forDisabled: 'Places for people with disabilities'
    },
    rate: 'rub/hr',
    startParking: 'Start parking',
    payParking: 'Pay for parking',
    free: 'Free'
  },
  parkomat: {
    title: 'Description'
  },
  days: {
    Рабочий: 'Weekday',
    Суббота: 'Saturday',
    Воскресенье: 'Sunday',
    Праздник: 'Holiday'
  },
  category: {
    Придорожная: 'On-street parking',
    Многоуровневая: 'Multi-level parking',
    Карман: 'Angle parking',
    Паркомат: 'Parking meter',
    [PARK_TYPES.PLANAR]: 'Planar'
  },
  type: {
    'Уличная парковка': 'On-street parking',
    'Коммерческая парковка': 'Commerce parking',
    'Многоуровневая': 'Multi-level parking',
    'Плоскостная парковка': 'Planar parkign'
  },
  parkingOperator: {
    tyumenGorTrans: 'Parking operator: MSI "Тюменьгортранс"'
  }
};

const reservationRu = {
  head: {
    zone: 'Зона'
  },
  parking: {
    places: {
      from: 'из',
      placesFree: 'мест свободно',
      totalPlaces: 'Всего мест',
      forDisabled: 'Мест для инвалидов'
    },
    rate: 'руб/час',
    startParking: 'Начать парковку',
    payParking: 'Оплатить парковку',
    free: 'Бесплатно'
  },
  parkomat: {
    title: 'Описание'
  },
  days: {
    Рабочий: 'Рабочий',
    Суббота: 'Суббота',
    Воскресенье: 'Воскресенье',
    Праздник: 'Праздник'
  },
  category: {
    Придорожная: 'Придорожная',
    Многоуровневая: 'Многоуровневая',
    Карман: 'Карман',
    Паркомат: 'Паркомат',
    [PARK_TYPES.FREE]: 'Бесплатная',
    [PARK_TYPES.FORDISABLED]: 'Для инвалидов',
    [PARK_TYPES.PLANAR]: 'Плоскостная'
  },
  type: {
    'Уличная парковка': 'Уличная парковка',
    'Коммерческая парковка': 'Коммерческая парковка',
    'Многоуровневая': 'Многоуровневая',
    'Плоскостная парковка': 'Плоскостная парковка'
  },
  parkingOperator: {
    tyumenGorTrans: 'Оператор парковки: МКУ "Тюменьгортранс"'
  }
};

export default {
  eng: reservationEng,
  ru: reservationRu
};
