import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { translate } from 'react-i18next';
import withTick from '../../Tick';

import { Dropdown, DropdownToggle, Button } from 'reactstrap';

import DropdownMenuMaterial from '../../Common/DropdownMenuMaterial';

import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody
} from 'react-accessible-accordion';

import Payment from '../../ProfileComponents/Payment';
import Link from '../../../Containers/Links/LngLink';
import { ControlChild } from '../../ControlGroup';
import Crumbs from '../../Header/Crumbs/Crumbs';
import { CancleSessionButton } from '../../Common/Buttons';
import Timer from '../../Timer';

import UserStore from '../../../Stores/UserStore';
import UIStore from '../../../Stores/UIStore';
import MapStore from '../../../Stores/MapStore';
import SettingsStore from '../../../Stores/SettingsStore';
import Moment from 'moment/moment';

import CancelNoBalanceSessionBtn from '../../Common/Buttons/Payments/CancelNoBalanceSessionBtn';

import ParkingIcon from '../../icons/ParkingSvg';

const CrumbsWithTick = withTick(Crumbs);
const TimerWithTick = withTick(Timer);

@translate(['header'], { wait: true })
@observer
class Parking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPaymentModalOpen: false,

      sale: 0,
      renewId: null,
      transport: '',

      zoneName: '',
      zone: {
        name: '',
        price: 0
      }
    };

    this.isProfileCalled = false;
  }

  render() {
    const { t } = this.props;

    const dropdowns = UIStore.dropdowns;
    const sessions = UserStore.getActiveSessions();

    const active = sessions.length ? 'active' : '';
    const parkedClass = sessions.length ? 'parked' : '';

    return (
      <Fragment>
        <Payment
          actionType="renew"
          isOpen={this.state.isPaymentModalOpen}
          onSubmit={this.closeRenewModal}
          onExit={this.closeRenewModal}
          data={this.getPaymentData()}
        />

        <Dropdown
          isOpen={dropdowns.dropdownOpenParking}
          className={parkedClass}
          toggle={this.toggleParking}
        >
          <DropdownToggle className="reset-style-btn">
            <ControlChild>
              <div className={`header__button ${active}`}>
                <ParkingIcon />
              </div>

              <div className={`header__button_title ${parkedClass}`}>{t('parking:parking')}</div>

              {this.renderParkingCrumbs()}
            </ControlChild>
          </DropdownToggle>

          <DropdownMenuMaterial className="parking" right>
            {sessions.length !== 0 ? (
              <Accordion ref={this.accordion}>
                {sessions.map((session, index) => (
                  <AccordionItem key={index} expanded={index === 0} className="accrodion-item">
                    <AccordionItemTitle
                      className={'accordion-item-title'}
                      style={{ position: 'relative' }}
                    >
                      <label>
                        {t('header:parkingInfo:zone')} #{session.zone}
                        <div className="accordion__arrow" role="presentation" />
                      </label>
                    </AccordionItemTitle>

                    <AccordionItemBody>
                      {this.renderCurrentParkingInfo(session)}
                      {this.renderRenewButton(index)}
                      {this.renderCancelButton(session)}
                    </AccordionItemBody>
                  </AccordionItem>
                ))}
              </Accordion>
            ) : (
              <p className="empty-session">{t('header:parkingInfo:infoNotFound')}.</p>
            )}
            <Link to={'/profile/parking'}>
              <Button
                onClick={this.parkingClick}
                className="drp-btn parking-info__button btn-sm center-btn w-100"
                color="primary"
              >
                {sessions.length !== 0
                  ? t('header:parkingInfo:allParking')
                  : t('header:parkingInfo:startParking')}
              </Button>
            </Link>
          </DropdownMenuMaterial>
        </Dropdown>
      </Fragment>
    );
  }

  dropdownInner() {
    const { sessions, t } = this.props;

    if (sessions.length !== 0) {
      <Accordion ref={this.accordion}>
        {sessions.map((session, index) => (
          <AccordionItem key={index} expanded={index === 0} className="accrodion-item">
            <AccordionItemTitle className={'accordion-item-title'} style={{ position: 'relative' }}>
              <label>
                {t('header:parkingInfo:zone')} #{session.zone}
                <div className="accordion__arrow" role="presentation" />
              </label>
            </AccordionItemTitle>

            <AccordionItemBody>
              {this.renderCurrentParkingInfo(session)}
              {this.renderRenewButton(index)}
              {this.renderCancelButton(session)}
            </AccordionItemBody>
          </AccordionItem>
        ))}
      </Accordion>;
    }
  }

  renderCurrentParkingInfo(session) {
    const { t } = this.props;
    const lastSession = UserStore.getLastSession();
    const { startDate, endDate } = session;

    if (lastSession && lastSession !== -1) {
      const startSessionTIme = this.getLocalFormatTime(startDate, 'HH:mm');
      const endSessionTime = this.getLocalFormatTime(endDate, 'HH:mm');

      const from = t('header:parkingInfo:from');
      const to = t('header:parkingInfo:to');

      const timeFromTo = `${from} ${startSessionTIme} ${to} ${endSessionTime}`;
      const dontUseBalance = SettingsStore.settings.dontUseBalance;

      return (
        <div className="park-tip-info">
          <div className="park-tip-info__body">
            <div className="park-tip-info__filed">
              {dontUseBalance ? null : (
                <TimerWithTick
                  onTick={!SettingsStore.settings.dontUseBalance ? this.handleTick : null}
                  selfLess={false}
                  startTime={startDate}
                  endTime={endDate}
                  textUnder={t('header:parkingInfo:left')}
                  textClassName={'text-black-50'}
                  textForPercentage={() => this.textForTimer(endDate)}
                />
              )}
            </div>

            <div className="park-tip-info__filed">
              <p>
                {t('header:parkingInfo:number')}: {session.transport.numberTs}
              </p>
            </div>
            {dontUseBalance ? null : (
              <div className="park-tip-info__filed">
                <p>{timeFromTo}</p>
              </div>
            )}
          </div>
        </div>
      );
    }

    return <div>{t('header:parkingInfo:infoNotFound')}.</div>;
  }

  renderParkingCrumbs() {
    const lastSession = UserStore.getLastSession();
    const endTimeOfParking = UserStore.getEndTimeOfparkingProfile();

    const crumbs =
      lastSession && lastSession !== -1 && !SettingsStore.settings.dontUseBalance ? (
        <CrumbsWithTick text={this.timeCrumbs} endTime={endTimeOfParking} />
      ) : null;

    return crumbs;
  }

  renderRenewButton(index) {
    const { t } = this.props;
    if (SettingsStore.settings.dontUseBalance) {
      return null;
    }

    return (
      <div className="parking__btn-container">
        <Button
          outline
          className="btn-rounded parking__btn parking__btn--extend"
          onClick={() => {
            this.openRenewModal();
            this.setPaymentData(index);
          }}
          color="primary"
        >
          {t('operations:extend')}
        </Button>
      </div>
    );
  }

  renderCancelButton(session) {
    if (SettingsStore.settings.dontUseBalance) {
      return (
        <div className="parking__btn-container">
          <CancelNoBalanceSessionBtn t={this.props.t} className="parking__btn" session={session} />
        </div>
      );
    }

    return (
      <div className="parking__btn-container">
        <CancleSessionButton className="parking__btn" session={session}>
          {this.props.t('operations:abort')}
        </CancleSessionButton>
      </div>
    );
  }

  /**
   * @description Коллбек, который вызывается каждую секунду и
   * обновляет профиль по истечении времени парковки
   */
  handleTick = () => {
    const now = Moment();
    const endTime = Moment(UserStore.getEndTimeOfparkingProfile());

    if (!this.isProfileCalled && Moment(endTime).valueOf() - Moment(now).valueOf() <= 0) {
      this.isProfileCalled = true;
      UserStore.updateProfile();
    }
  };

  parkingClick = () => {
    UIStore.toggleDropDown('Parking');
  };

  toggleParking = () => {
    UIStore.toggleDropDown('Parking');
  };

  timeCrumbs = () => {
    const times = this.getDifferenteTime(UserStore.getEndTimeOfparkingProfile());

    return `${times.hours}ч${times.minutes}м`;
  };

  textForTimer = endTime => {
    const times = this.getDifferenteTime(endTime);

    return `${times.hours}:${times.minutes}`;
  };

  /**
   * @param time
   * @param format
   * @description Возвращает время в таймзоне пк, в заданном формате.
   */
  getLocalFormatTime(time, format) {
    return Moment(time)
      .utc()
      .local()
      .format(format);
  }

  openRenewModal = () => {
    this.setState({
      isPaymentModalOpen: true
    });
  };

  closeRenewModal = async completed => {
    this.setState({
      isPaymentModalOpen: false
    });

    completed && (await this.updateData());
  };

  updateData() {
    UserStore.updateAll();
  }

  setPaymentData(index) {
    const sessions = UserStore.getSessions();
    const selectedSession = sessions[index];

    this.setState({
      renewId: selectedSession.id,
      transport: selectedSession.transport.numberTs,
      zoneName: selectedSession.zone,
      parkingCode: selectedSession.parkingCode,
      endDate: selectedSession.endDate
    });
  }

  getPaymentData = () => {
    return {
      id: this.state.renewId,
      transport: this.state.transport,
      sale: 0,
      parkingCode: this.state.parkingCode,
      endDate: this.state.endDate,
      zone: {
        name: this.state.zoneName,
        price: this._getZonePriceAndPeriod(this.state.zoneName).price
      }
    };
  };

  /**
   *@description Возвращает разницу во времени
   *             между настоящим и полученным
   */

  getDifferenteTime = endTimeOfParking => {
    const times = ['hours', 'minutes'];

    const now = Moment();
    const endTime = Moment(endTimeOfParking);

    const minutes = Moment(Moment(endTime).diff(Moment(now)));

    if (Moment(endTime).valueOf() - Moment(now).valueOf() >= 0) {
      return {
        hours: endTime.diff(now, times[0]),
        minutes: minutes.format('mm')
      };
    }

    return {
      hours: 0,
      minutes: 0
    };
  };

  _getZonePriceAndPeriod = zone => {
    let periodFound = false;
    let foundZone = null;

    if (typeof zone === 'string') {
      foundZone = MapStore.getZones().find(
        z => String(z.name) === String(zone) || String(z.value) === String(zone)
      );
    } else {
      foundZone = zone;
    }

    if (!foundZone) {
      return {
        price: 0,
        period: null
      };
    }

    const { prices } = foundZone;

    const today = (() => {
      const day = Moment().isoWeekday();

      if (day === 6) {
        return 'saturday';
      }

      if (day === 7) {
        return 'sunday';
      }

      return 'default';
    })();

    prices.forEach(period => {
      if (periodFound) {
        return;
      }

      if (period.type === today) {
        const { from, to } = period.interval;

        const start = Moment(from, 'HH:mm:ss');
        const end = Moment(to, 'HH:mm:ss');

        if (Moment().isBetween(start, end)) {
          periodFound = period;
        }
      }
    });

    if (!periodFound) {
      return {
        price: 0,
        period: null
      };
    }

    const start = Moment(periodFound.interval.from, 'HH:mm:ss').format('HH:mm');
    const end = Moment(periodFound.interval.to, 'HH:mm:ss').format('HH:mm');

    return {
      price: periodFound.price,
      period: start + ' - ' + end
    };
  };
}

export default Parking;
