import React, { Component } from 'react';
import NavLink from '../Common/NavLink';
import { Row, Col } from 'reactstrap';

class ProfileMenu extends Component {
  static defaultProps = {
    url: '/profile/',
    center: true,
    lg: 6,
    noMargin: false,
  };

  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    const { center, lg, noMargin } = this.props;
    let menuItems = this.getMenuItems();
    const centerClassName = center ? 'justify-content-center' : '';
    const noMarginStyle = noMargin ? { margin: 0 } : {};

    return (
      <div className="profile-menu" style={noMarginStyle}>
        <Row noGutters className="justify-content-center">
          <Col lg={lg} xs={12} className="menu-container">
            <Row noGutters className={centerClassName}>
              {menuItems}
            </Row>
          </Col>
        </Row>
      </div>
    );
  }

  getMenuItems = () => {
    const { url } = this.props;

    return this.props.menuItems.map((item, i) => (
      <NavLink
        key={i}
        to={url + item.type}
        className={'item'}
        activeClassName={'active'}
        exact={item.exact}
      >
        {item.name}
      </NavLink>
    ));
  };
}

export default ProfileMenu;
