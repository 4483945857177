import React from 'react';
import * as Yup from 'yup';
import MenuItem from '@material-ui/core/MenuItem';
import { useFormik, yupToFormErrors } from 'formik';
import Button from 'reactstrap/lib/Button';
import Alert from 'reactstrap/lib/Alert';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import i18n from 'i18next';

import FormControl from '../../common/FormControl';
import {
  FormikSelect,
  TsSelect as FormikAutoSelect,
} from '../../../../Common/Inputs';
import SpinnerButton from '../../../../Common/Buttons/SpinnerButton';
import AutoSubmit from '../../../../Common/Inputs/AutoSubmit';
import CustomDatePickerInput from '../../common/DatePickerInput';
import FloatPrice from '../../../../Common/FloatPrice';
import AbonementStore from "../../../../../Stores/AbonementStore";

const SubscriptionForm = ({
  t,
  toPaymentService,
  typeSubscription,
  price,
  moneyEnough,
  amountToPay,
  dontUseBalance,
  dateStart = moment(),
  minDate = moment(),
  maxDate = moment(),
  ts = [],
  services = [],
  phones = [],
  gettingPrice,
  onGetPrice = () => null,
  onChange = () => null,
  onSubmit = () => null,
  onAbort = () => null,
}) => {
  const submitRef = React.useRef(null);

  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      service:
        amountToPay > 0 ? (services.length > 0 ? services[0] : null) : null,
      phone: phones.length > 0 ? phones[0] : null,
      ts: ts.length > 0 ? ts[0] : null,
      date: dateStart,
    },
    // Validation phase
    validate: (values) => {
      const phoneRegExp = /^((\+7|7|8)+([0-9]){10})$/;
      const schema = Yup.object().shape({
        ts: Yup.object()
          .nullable()
          .required(t('errors:RequiredField')),
        service: Yup.object().nullable(),
        phone: Yup.object().when('service', {
          is: (val) => val && val.isPhoneRequired,
          then: Yup.object().shape({
            phone: Yup.string()
              .required(t('errors:RequiredField'))
              .matches(phoneRegExp, t('errors:InvalidFormat')),
            isMain: Yup.bool(),
          }),
          otherwise: Yup.object().nullable(),
        }),
        date: Yup.object()
          .nullable()
          .required(t('errors:RequiredField')),
      });

      // Catch Yup errors
      try {
        schema.validateSync(values);
      } catch (validationError) {
        return yupToFormErrors(validationError);
      }
      return {};
    },
    // Submit phase
    onSubmit: async (values, { resetForm, setStatus }) => {
      if (onSubmit) {
        const response = await onSubmit(values);
        console.log('on submit');
        if (response && response.status !== 'ok') {
          setStatus(t(`errors:${response.errorName}`));
        } else {
          resetForm({});
          setStatus(null);
        }
      }
    },
  });

  const handleAbort = React.useCallback(() => {
    if (onAbort) {
      onAbort();
    }
  });

  const {
    values,
    isSubmitting,
    status,
    isValid,
    errors,
    setStatus,
    setSubmitting,
    handleSubmit,
    // submitForm
  } = formik;

  React.useEffect(() => {
    if (submitRef && submitRef.current) {
      submitRef.current.href = toPaymentService;
    }
  });

  return (
    <>
      <form onSubmit={moneyEnough ? handleSubmit : (e) => e.preventDefault()}>
        {status ? <Alert color="danger">{status}</Alert> : null}
        <FormControl fullWidth>
          <FormikSelect
            form={formik}
            field={formik.getFieldProps({ name: 'ts' })}
            label="Транспорт"
            name="ts"
            items={ts}
            renderValue={(ts) => {
              return ts.numberTs;
            }}
            renderItems={(items) =>
              items.map((ts) => (
                <MenuItem key={ts.numberTs} value={ts}>
                  <span>{ts.numberTs}</span>
                </MenuItem>
              ))
            }
          />
        </FormControl>
        <FormControl fullWidth>
          <DatePicker
            minDate={minDate}
            maxDate={maxDate}
            customInput={<CustomDatePickerInput />}
            locale={i18n.language}
            dateFormat="DD/MM/YYYY"
            selected={values.date}
            disabled={isSubmitting || typeSubscription === AbonementStore.TYPES.PREV_DAY}
            onChange={(date) => {
              formik.setFieldValue('date', date);
            }}
            placeholderText={'Начало действия абонемента'}
          />
        </FormControl>
        {!moneyEnough ? (
          <>
            <FormControl fullWidth>
              <FormikSelect
                form={formik}
                field={formik.getFieldProps({ name: 'service' })}
                label="Методы оплаты"
                name="service"
                items={services}
                renderValue={(value) => {
                  return value.methodNameForShow;
                }}
                renderItems={(items) =>
                  items.map((item) => (
                    <MenuItem key={item.methodNameForShow} value={item}>
                      <span>
                        <span>{item.methodNameForShow}</span>
                        <div className="text-secondary">
                          {item.methodDescription}
                        </div>
                      </span>
                    </MenuItem>
                  ))
                }
              />
            </FormControl>
            <FormControl fullWidth>
              {values.service && values.service.isPhoneRequired ? (
                <FormikAutoSelect
                  field={formik.getFieldProps('phone')}
                  form={formik}
                  label="Номер телефона"
                  name="phone"
                  options={phones}
                  clearOnEscape={false}
                  disableClearable
                  noOptionsText="Добавьте номер телефона в профиль"
                  getOptionLabel={(phone) => phone.phone}
                />
              ) : null}
            </FormControl>
            <FormControl fullWidth>
              <p>
                Вы будете перенаправлены на страницу платёжной системы, где
                сможете произвести оплату.
              </p>
            </FormControl>
          </>
        ) : null}

        <FormControl>
          <div>
            {dontUseBalance || moneyEnough ? (
              <>
                {t('parking:currentParkings:amountToPaid')} &nbsp;
                <FloatPrice number={price} className="sum neutral" />
              </>
            ) : (
              <>
                {t('parking:currentParkings:toDeposite')} &nbsp;
                <FloatPrice number={amountToPay} className="sum neutral" />
              </>
            )}
          </div>
        </FormControl>
        <div>
          {moneyEnough ? (
            <SpinnerButton
              tag={'button'}
              color="primary"
              type="submit"
              className="submit"
              innerRef={submitRef}
              style={{ appearance: 'none' }}
              isLoading={isSubmitting}
              disabled={
                moneyEnough
                  ? !isValid || isSubmitting
                  : !toPaymentService || !!status
              }
              onClick={moneyEnough ? null : () => onSubmit(values)}
            >
              {t('operations:pay')}
            </SpinnerButton>
          ) : !toPaymentService ? (
            <SpinnerButton
              tag={'button'}
              color="primary"
              type="submit"
              className="submit"
              target="_blank"
              innerRef={submitRef}
              style={{ appearance: 'none' }}
              isLoading={gettingPrice}
              disabled={!isValid || gettingPrice}
              onClick={() => onGetPrice(values)}
            >
              Получить ссылку на оплату
            </SpinnerButton>
          ) : (
            <SpinnerButton
              tag={'a'}
              color="primary"
              className="submit"
              target="_blank"
              innerRef={submitRef}
              style={{ appearance: 'none' }}
              disabled={!toPaymentService || !!status}
            >
              {t('operations:pay')}
            </SpinnerButton>
          )}

          <Button
            outline
            color="primary"
            onClick={handleAbort}
            className="cancel"
          >
            {t('operations:annulment')}
          </Button>
        </div>
        {!moneyEnough ? (
          <AutoSubmit
            t={t}
            submitForm={onChange}
            values={values}
            isValid={isValid && isEmpty(errors)}
            setSubmitting={setSubmitting}
            setStatus={setStatus}
          />
        ) : null}
      </form>
    </>
  );
};

export default SubscriptionForm;
