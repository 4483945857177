const PARK_TYPES = {
  ROADSIDE: 'Придорожная',
  MULTILEVEL: 'Многоуровневая',
  PLANAR: 'Плоскостная',
  COMMERCE: 'Коммерческая',
  FREE: 'Бесплатная',
  FORDISABLED: 'Парковка для инвалидов',
  POCKET: 'Карман',
  PARKOMAT: 'Паркомат',
  PAYMENTTERMINAL: 'Пункт приема оплаты',
  INFORMATIONTABLE: 'Информационное табло',
  DEFAULT: 'default'
};

export default PARK_TYPES;
