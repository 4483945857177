import React from 'react';

import './style/index.css';

function WeAreClosed() {
  return (
    <div className="we-are-closed">
      <div className="we-are-closed__inner">
        <div className="we-are-closed__title">Извините, мы закрыты</div>
        <div className="we-are-closed__ps">но обещаем, что скоро вернемся!</div>
      </div>
    </div>
  );
}

export default WeAreClosed;
