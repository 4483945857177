import React, { Component, Fragment } from "react";
import { observer } from "mobx-react";
import { observable } from "mobx";
import { translate } from "react-i18next";

import FloatPrice from "../../../Components/Common/FloatPrice";
import FontAwesome from "@fortawesome/react-fontawesome";
import car from "@fortawesome/fontawesome-free-solid/faCar";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import faClock from "@fortawesome/fontawesome-free-solid/faClock";

import ErrorStore from "../../../Stores/ErrorStore";

import forIn from "lodash.forin";

import Payment from "../../../Components/ProfileComponents/Payment";
import { SectionBlock } from "../../../Components/ProfileComponents";
import { Alert, Row, Col, Button } from "reactstrap";
import { CancleSessionButton } from "../../../Components/Common/Buttons";
import FieldPreLoader from "../../../Components/FieldPreLoader";
import {
  Input,
  Select,
  SearchSelect
} from "../../../Components/MaterialDesign";
import { ParkingInput } from "../../../Components/Common/Inputs";
import IntervalBlock from "../../../Components/ProfileComponents/Payment/IntervalsBlock";

import PaymentStore from "../../../Stores/PaymentStore";
import userStore from "../../../Stores/UserStore";
import ProfileParkingStore from "../../../Stores/ProfileParkingStore";
import MapStore from "../../../Stores/MapStore";
import CarNumberStore from "../../../Stores/CarNumberStore";

import moment from "moment";
import SettingsStore from "../../../Stores/SettingsStore";
import PlanarPaymentContainer from "./PlanarPayment/Container";

@translate(["parking"], { wait: true })
@observer
export default class Parking extends Component {
  @observable allDataLoaded = false;
  @observable parkingIsUpdating = false;

  @observable selectedParkingType = "roadside";

  @observable selectedZone = "";
  @observable parkingCode = "";

  @observable selectedTransport = "";
  @observable selectedDuration = "";

  @observable priceVisible = false;
  @observable validError = false;
  @observable price = 0;
  @observable priceRest = "00";
  @observable moneyEnough = true;
  @observable sale = 0;
  @observable sumSale = 0;
  @observable benefitsName = [];
  @observable costData = null;
  @observable paymentStep = false;
  @observable cardNumberIsValid = false;

  @observable postTimeError = false;
  @observable postTimeErrorMessage = "";
  @observable costError = false;
  @observable actionType = "start";

  @observable costLoaded = false;
  @observable isAlertVisible = false;
  @observable alertText = "";
  @observable selectedRenewTransport = null;
  @observable selectedRenewZone = null;
  @observable selectedRenewId = 0;
  @observable renewEndDate = null;
  @observable renewSumSale = null;

  @observable paymentType = "today";
  @observable postPaymentStartTime = null;

  oldTransport = "";

  _refs = {
    zone: React.createRef(),
    transport: React.createRef(),
    time: React.createRef(),
    card: React.createRef(),
    searchselect: React.createRef()
  };

  constructor(props) {
    super(props);

    this.props = props;
    this.t = props.t;
    this._handleZoneSelect = this._handleSelect.bind(this, "zone");
    this._handleTransportSelect = this._handleSelect.bind(this, "transport");
    this._handleDurationSelect = this._handleSelect.bind(this, "duration");
  }

  async componentDidMount() {
    await this.fetch();
    this.selectedZone = ProfileParkingStore.getDefaultZoneNumber() || "";
    this.parkingCode = ProfileParkingStore.parkingCode || "";
  }

  UNSAFE_componentWillReceiveProps() {
    this.setZones(MapStore.getZones());
  }

  componentWillUnmount() {
    ProfileParkingStore.setDefaultZoneNumber(null);
    ProfileParkingStore.parkingCode = null;
  }

  render() {
    if (this.isLoadError()) {
      return this._renderError();
    }

    return (
      <Fragment>
        <Payment
          isOpen={this.paymentStep}
          actionType={this.actionType}
          data={this._getPaymentData()}
          onSubmit={this._handleClosePaymentModal}
          onExit={this._handleClosePaymentModal}
          costData={this.costData}
        />

        <Row
          noGutters
          style={{ flex: 1 }}
          className="profile-parking justify-content-center"
        >
          <Col md={12} lg={8}>
            <Row
              noGutters
              className="justify-content-center flex-column-reverse flex-lg-row"
            >
              <Col md={12} lg={6}>
                <div className="md-card">
                  {this.renderAlert()}
                  <SectionBlock
                    title={this.t("parking:payments:title")}
                    isLoading={!this.isAllDataFetched()}
                  >
                    <div className="main-wrapper">
                      {this._renderParkingPayment()}
                    </div>
                  </SectionBlock>
                </div>
              </Col>

              <Col md={12} lg={6}>
                <div className="md-card">
                  <SectionBlock
                    title={this.t("parking:currentParkings:title")}
                    isLoading={!this.isAllDataFetched()}
                  >
                    <div className="main-wrapper">
                      {this._renderCurrentParking()}
                    </div>
                  </SectionBlock>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Fragment>
    );
  }

  renderAlert() {
    const alert = this.isAlertVisible ? (
      <Alert color="danger">{this.alertText}</Alert>
    ) : null;
    return alert;
  }

  _renderError = () => {
    const { t } = this.props;
    return (
      <Row
        noGutters
        className="profile-abonements justify-content-center"
        style={{ flex: 1 }}
      >
        <Col md={12} lg={8} className="main-wrapper">
          <div className="md-card">
            <h5>{t("operationsHistory:error")}</h5>
            <div className="text-secondary">
              {t("operationsHistory:errorMessage")}
            </div>
            <div className="error-buttons">
              <button
                style={{
                  fontSize: 14,
                  margin: 0
                }}
                onClick={this.fetch}
                className="menu-login btn btn-primary btn-small-round logout"
              >
                {t("operationsHistory:tryAgain")}
              </button>
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  _renderParkingPayment = () => {
    if (!this.isAllDataFetched() || this.parkingIsUpdating) {
      return (
        <Fragment>
          <FieldPreLoader
            visible
            style={{
              height: 31,
              width: 300,
              marginTop: 22
            }}
          />
          <FieldPreLoader visible style={{ height: 35, marginTop: 25 }} />
          <FieldPreLoader visible style={{ height: 35, marginTop: 15 }} />
          <FieldPreLoader visible style={{ height: 35, marginTop: 15 }} />
          <FieldPreLoader
            visible
            style={{
              height: 35,
              width: 200,
              marginTop: 25
            }}
          />
        </Fragment>
      );
    }

    return (
      <Fragment>
        <div className="info-field">
          <div className="value">
            <Button
              className={this._getParkingTypeButtonClass("roadside")}
              color={this._getParkingTypeButtonColor("roadside")}
              data-type="roadside"
              onClick={this._setParkingType}
            >
              {this.t("parking:payments:parkingZones:roadSide")}
            </Button>
            {SettingsStore.settings.useFlatParking ? (
              <Button
                className={this._getParkingTypeButtonClass("planar")}
                color={this._getParkingTypeButtonClass("planar")}
                data-type="planar"
                onClick={this._setParkingType}
              >
                {this.t("parking:payments:parkingZones:flatBarrier")}
              </Button>
            ) : null}
          </div>
        </div>

        {this.selectedParkingType === "planar"
          ? this._renderPlanarContent()
          : this._renderRoadsideContent()}
      </Fragment>
    );
  };

  submitText() {
    if (Boolean(this.costData) && this.costData.totalcost === 0) {
      return this.t("reservation:parking:free");
    }

    if (this.moneyEnough) {
      return this.t("operations:startParking");
    } else {
      return this.t("operations:continue");
    }
  }

  _renderRoadsideContent = () => {
    const {
      inputTransportOptions,
      selectTransportOptions
    } = this.getSearchSelectOptions();

    const balance = userStore.getBalance();
    const balanceText = balance === -1 ? 0 : balance;
    const discount = String(this.sumSale).split(".");

    if (MapStore.getParkings.length > 0) {
      return (
        <Fragment>
          <div className={"parking-select"}>{this.renderParkingIput()}</div>

          <div className={"ts-select"}>
            <SearchSelect
              inputOptions={inputTransportOptions}
              value={this.selectedTransport}
              selectOptions={selectTransportOptions}
              onAction={this.validateTransportField}
              ref={this._refs.searchselect}
            />
          </div>

          <div className={"time-select"}>
            <Select
              label={this.t("parking:payments:labels:duration")}
              options={this.getTimeintervals()}
              ref={this._refs.time}
              onSelect={this._handleDurationSelect}
              tabIndex={0}
              filter={false}
            />
          </div>

          {this.isAllGood() ? (
            <div className="calculate-result text-secondary">
              <span>{this.t("parking:payments:costParking")} </span>
              <span className="sum neutral">
                <span>{this.price}</span>
                <span className="rest">.{this.priceRest}</span>
              </span>

              <span>. </span>

              {this.sumSale ? (
                <span>
                  <span>
                    {this.renderBenefits()}
                    <span>
                      ({this.t("parking:payments:saved")} &nbsp;
                      <span className="sum neutral">
                        <FloatPrice number={this.sumSale} />
                      </span>
                      )
                    </span>
                  </span>
                  <span>. </span>
                </span>
              ) : null}

              {!this.moneyEnough ? (
                <div style={{ marginTop: 15 }}>
                  <span>
                    {this.t("parking:payments:insufficientFunds")}&nbsp;
                    <span className="sum neutral">{this.costData.refill}</span>
                  </span>
                  <span>{this.t("parking:payments:because")} &nbsp;</span>
                  <span className="sum neutral">
                    <span>{balanceText}</span>
                    {balanceText && String(balanceText).search(".") === -1 ? (
                      <span className="rest">.00</span>
                    ) : null}
                  </span>
                  <span>
                    .{this.t("parking:payments:pressContinueMessage")}
                  </span>
                </div>
              ) : null}
            </div>
          ) : null}

          {this.renderIntervalBlocks()}

          {(SettingsStore.settings.usePostPayment || SettingsStore.settings.usePostPaymentYesterday)
            ? this.renderTimeButtons(SettingsStore.settings.usePostPayment, SettingsStore.settings.usePostPaymentYesterday)
            : null}

          <Button
            outline
            disabled={!this.isAllGood()}
            color="primary"
            onClick={this._goToPaymentStep}
            className="submit"
          >
            {this.submitText()}
          </Button>
        </Fragment>
      );
    }
  };

  isAllGood() {
    const isCostData =
      this.checkPriceVisibility() &&
      Boolean(this.costData) &&
      this.costData.intervals &&
      this.costData.intervals.length !== 0;

    const isError = this.costError || this.validError;
    return isCostData && !isError;
  }

  renderIntervalBlocks() {
    const { t } = this.props;

    if (this.isAllGood()) {
      return (
        <IntervalBlock
          costData={this.costData}
          periodTitle={t("parking:payments:intervals:period")}
          costTitle={t("parking:payments:intervals:cost")}
          benefitTitle={t("parking:payments:intervals:benefit")}
        />
      );
    }

    return null;
  }

  renderBenefits() {
    if (this.benefitsName) {
      const message = (
        <span>
          {this.benefitsName.length === 1
            ? this.t("parking:payments:privilegeApplied")
            : this.t("parking:payments:privilegesApplied")}
          &nbsp;
        </span>
      );

      const benefits = Array.isArray(this.benefitsName)
        ? this.benefitsName.join(",")
        : this.benefitsName;

      return (
        <Fragment>
          {message}
          {benefits}
        </Fragment>
      );
    }

    return null;
  }

  handleSuccessPostTimePick = time => {
    this.postPaymentStartTime = new Date(time);
    this.postTimeError = false;
    this.postTimeErrorMessage = "";
  };

  handleFailurePostTimePick = time => {
    this.postTimeError = true;
    this.postTimeErrorMessage = "Нельзя выбрать будущее время при постоплате";
  };

  handleTimePick = async time => {
      this.validatePostTime(
          time,
          this.handleSuccessPostTimePick,
          this.handleFailurePostTimePick,
          this.paymentType
      );
      if (this.postPaymentStartTime !== null) {
        await this.costOfSession();
      }
  };

  renderTimeButtons = (postPaymentToday = false, postPaymentYesterday = false) => {
    const { t } = this.props;
    return (
      <div>
        <Row
          style={{
            marginBottom: 20
          }}
        >
          <Col>
            <Button
              className={"payment fromToday"}
              color={this.paymentType === "today" ? "primary" : "light"}
              data-type="fromToday"
              onClick={async () => {
                this.postPaymentStartTime = null;
                this.paymentType = "today";
                await this.costOfSession();
              }}
            >
              {t("parking:payments:paymentTypes:today")}
            </Button>
            {postPaymentToday &&
              <Button
                  className={"payment postPayment"}
                  color={this.paymentType === "postPayment" ? "primary" : "light"}
                  data-type="postPayment"
                  onClick={async () => {
                    this.postPaymentStartTime = null;
                    this.paymentType = "postPayment";
                    this.handleTimePick(null);
                  }}
              >
                {t("parking:payments:paymentTypes:postPayment")}
              </Button>
            }
            {postPaymentYesterday &&
              <Button
                  className={"payment postPayment"}
                  color={this.paymentType === "postPaymentYesterday" ? "primary" : "light"}
                  data-type="postPaymentYesterday"
                  onClick={async () => {
                    this.postPaymentStartTime = null;
                    this.paymentType = "postPaymentYesterday";
                    this.handleTimePick(null);
                  }}
              >
                {t("parking:payments:paymentTypes:postPaymentYesterday")}
              </Button>
            }
          </Col>
        </Row>
        {(this.paymentType === "postPayment" || this.paymentType === "postPaymentYesterday") ? (
          <Row style={{ marginBottom: 20 }}>
            <Col>
              <Row>
                <Col>
                  <div>
                    <TimePicker
                      value={this.postPaymentStartTime ? moment(this.postPaymentStartTime) : null}
                      placeholder={t("parking:payments:timePicker:placeholder")}
                      showSecond={false}
                      inputIcon={
                        <FontAwesome
                          style={{
                            transform: "translate(-22px, 6px)",
                            position: "absolute"
                          }}
                          icon={faClock}
                        />
                      }
                      onChange={this.handleTimePick}
                    />
                    <div style={{ color: "red", fontSize: 10, marginTop: 4 }}>
                      {this.postTimeErrorMessage}
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p
                    style={{ fontSize: 13, marginTop: "1rem", marginBottom: 0 }}
                  >
                    {t(`parking:payments:${this.paymentType}Messages:first`)}
                    <br />
                    {t(`parking:payments:${this.paymentType}Messages:second`)}
                    <br />
                    <span style={{ fontWeight: 600 }}>
                      {t(`parking:payments:${this.paymentType}Messages:third`)}
                    </span>
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        ) : null}
      </div>
    );
  };

  _renderPlanarContent = () => {
    return <PlanarPaymentContainer />;
  };

  _renderCurrentParking = () => {
    if (!this.isAllDataFetched() || this.parkingIsUpdating) {
      return (
        <Fragment>
          <FieldPreLoader visible style={{ height: 25, marginBottom: 10 }} />
          <FieldPreLoader visible style={{ height: 25, marginBottom: 10 }} />
          <FieldPreLoader visible style={{ height: 25, marginBottom: 10 }} />
        </Fragment>
      );
    }

    if (userStore.getSessions().length === 0) {
      return (
        <span className="text-secondary">
          {this.t("parking:currentParkings:noParkingMessage")}
        </span>
      );
    }

    return <div className="sessions-list">{this._renderSessionsRows()}</div>;
  };

  _renderSessionsRows = () => {
    const at = this.t("parking:currentParkings:remaining:at");

    return userStore.getSessions().map((session, index) => {
      let remaining = null;
      const end = moment(session.endDate);

      if (end.clone().diff(moment(), "minutes") <= 15) {
        remaining =
          this.t("parking:currentParkings:remaining:now") + " " + end.fromNow();
      } else if (
        end
          .clone()
          .startOf("day")
          .diff(moment().startOf("day"), "days") === 0
      ) {
        remaining =
          this.t("parking:currentParkings:remaining:todayAt") +
          " " +
          end.format("HH:mm");
      } else {
        remaining = `${this.t("parking:currentParkings:remaining:now")}   
                     ${end.format("DD MMMM")} ${at} ${end.format("HH:mm")}`;
      }
      return (
        <div className="row list-item" key={"row-" + index}>
          <div className="d-flex flex-column justify-content-center">
            <span className="text-black-50" style={{ marginRight: 15 }}>
              {SettingsStore.getZoneOrParkingLabel()}#{session.zone}
            </span>

            <span>
              <FontAwesome icon={car} />
              <span style={{ marginLeft: 5 }}>
                {session.transport.numberTs}
              </span>
            </span>

            <div className="text-secondary">{remaining}</div>
          </div>

          <div className="d-flex buttons">
            <Button
              outline
              className="btn-rounded"
              onClick={() => {
                this._openRenewModal(session);
              }}
              color="primary"
            >
              {this.t("operations:extend")}
            </Button>

            <CancleSessionButton onClick={this.resetForm} session={session}>
              {this.t("operations:abort")}
            </CancleSessionButton>
          </div>
        </div>
      );
    });
  };

  renderParkingIput() {
    if (!SettingsStore.settings.paymentByParkingId) {
      const { selectZoneOptions, inputZoneOptions } = this.getParkingOptions();
      return (
        <SearchSelect
          inputOptions={inputZoneOptions}
          selectOptions={selectZoneOptions}
          ref={this._refs.zone}
          bcsClr
          onlySelect
        />
      );
    } else {
      return (
        <ParkingInput
          parkings={this.getParkings()}
          defaultValue={{
            value: this.parkingCode,
            payload: { zonenumber: this.selectedZone }
          }}
          onSelect={this._handleZoneSelect}
          t={this.props.t}
          ref={this._refs.zone}
        />
      );
    }
  }

  getParkingOptions() {
    const defaultZoneNumber =
      ProfileParkingStore.getDefaultZoneNumber() || null;

    const defaultForSearchselect = defaultZoneNumber
      ? { value: defaultZoneNumber, payload: { zonenumber: defaultZoneNumber } }
      : "";
    const defaultForParkingInput = defaultZoneNumber;
    const defaultValue = SettingsStore.settings.paymentByParkingId
      ? defaultForParkingInput
      : defaultForSearchselect;

    const selectZoneOptions = {
      label: SettingsStore.getZoneOrParkingLabel(),
      options: this.setZones(MapStore.getZonesWithoutPlanar()),
      onSelect: this._handleZoneSelect,
      defaultValue
    };

    const inputZoneOptions = {
      name: "zone",
      id: "zone",
      label: SettingsStore.getZoneOrParkingLabel(),
      type: "text",
      onChange: this._handleZoneSelect
    };

    return {
      selectZoneOptions,
      inputZoneOptions
    };
  }

  getTimeintervals = () => {
    const times = SettingsStore.settings.intervals;

    if (times) {
      return ProfileParkingStore.timesWithInterval(
        times.step,
        times.max,
        times.min
      );
    }

    return ProfileParkingStore.timesWithInterval();
  };

  _openRenewModal = session => {
    this.selectedRenewId = session.id;
    this.selectedRenewTransport = session.transport.numberTs;
    this.selectedRenewZone = session.zone;
    this.renewEndDate = session.endDate;
    this.renewParkingCode = session.parkingCode;
    this.reservation = session.reservationId;

    this.actionType = "renew";
    this.paymentStep = true;
  };

  _goToPaymentStep = async () => {
    this.validateTransportField();
    const startTime = this.getPostPaymentStartTime(this.paymentType, this.postPaymentStartTime);
    this.validatePostTime(
      startTime,
      this.handleSuccessPostTimePick,
      this.handleFailurePostTimePick,
      this.paymentType
    );

    if (!this.moneyEnough) {
      this.paymentStep = true;

      return;
    }

    if (this.postTimeError) {
      return;
    }

    if (this.validError) return;

    const ts = userStore.getCarByNumber(this.selectedTransport.toUpperCase());
    const category = ts ? ts.category : null;
    const formatGrz = ts ? ts.formatGrz : null;
    const paymentStartType =
      this.paymentType === "today"
        ? PaymentStore.PAYMENT_TYPES.PAYMENT
        : PaymentStore.PAYMENT_TYPES.POST_PAYMENT;
    const result = await PaymentStore.paySession(paymentStartType, {
      number: this.selectedTransport.toUpperCase(),
      category: category,
      numberFormat: formatGrz,
      payment: "balance",
      zone: this.selectedZone,
      duration: this.selectedDuration,
      parkingCode: this.parkingCode,
      postPaymentStartTime: startTime
    });
    await userStore.updateProfile();
    if (result && result.hasOwnProperty("RedirectURL")) {
      window.location.href = result.RedirectURL;
    }

    this.resetForm();
  };

  resetForm = () => {
    ProfileParkingStore.setDefaultZoneNumber(null);
    this.price = 0;
    this._refs.searchselect.current.reset();
    this.selectedTransport = "";
    this._refs.searchselect.current.reset();
    this._refs.zone.current.reset();
    this.selectedDuration = "";
    this.costData = null;
    this.priceVisible = false;
    this.postPaymentStartTime = null;
  };

  _compareValues = (currentValue, expectedValue, successValue, failValue) => {
    if (currentValue === expectedValue) {
      return successValue;
    }

    return failValue;
  };

  _getParkingTypeButtonClass = filter => {
    return this._compareValues(
      this.selectedParkingType,
      filter,
      "btn-options",
      "btn-options-grey"
    );
  };

  _getParkingTypeButtonColor = filter => {
    return this._compareValues(
      this.selectedParkingType,
      filter,
      "primary",
      "light"
    );
  };

  _setParkingType = event => {
    const target = event.target;

    if (target.getAttribute) {
      const filter = target.getAttribute("data-type");

      if (!!filter) {
        this.selectedParkingType = filter;
      }
    }
  };

  _getPaymentData = () => {
    switch (this.actionType) {
      case "start":
        return {
          duration: this.selectedDuration,
          transport: this.selectedTransport,
          sale: this.sumSale,
          parkingCode: this.parkingCode,
          postPaymentStartTime:
              (this.paymentType === "postPayment" || this.paymentType === "postPaymentYesterday") && this.postPaymentStartTime
              ? this.postPaymentStartTime
              : null,
          zone: {
            name: this.selectedZone
          }
        };

      case "renew":
        return {
          id: this.selectedRenewId,
          transport: this.selectedRenewTransport,
          sale: this.renewSumSale,
          endDate: this.renewEndDate,
          parkingCode: this.renewParkingCode,

          zone: {
            name: this.selectedRenewZone
          }
        };

      default:
        return {};
    }
  };

  _handleClosePaymentModal = async (completed, zone) => {
    this.paymentStep = false;
    completed && (await this._updateParking());
  };

  _handleSelect = (select, selected) => {
    let refreshCost = false;

    switch (select) {
      case "zone": {
        const isPayloadExist = selected.hasOwnProperty("payload");
        const zonenumber = isPayloadExist ? selected.payload.zonenumber : "";

        const oldParkingCode = this.parkingCode;

        if (
          oldParkingCode !== selected.value ||
          this.selectedZone !== zonenumber
        )
          refreshCost = true;

        this.selectedOptionZone = selected;
        this.parkingCode = selected.value;
        this.selectedZone = zonenumber;

        break;
      }

      case "transport": {
        const oldTransport = this.selectedTransport;
        if (oldTransport !== selected.value) refreshCost = true;
        this.selectedOptionTransport = selected;
        this.selectedTransport = String(selected.value).replace(/\s/g, "");
        this.validateTransportField();
        break;
      }

      case "duration": {
        const oldDuration = this.selectedDuration;
        if (oldDuration !== selected.value) refreshCost = true;
        this.selectedOptionDuration = selected;

        this.selectedDuration = selected.value;
        break;
      }

      default:
        break;
    }

    if (refreshCost) {
      this.costOfSession();
    }

    if (this.checkPriceVisibility()) {
      this.priceVisible = true;
    } else {
      this.priceVisible = false;
      return;
    }
  };

  costOfSession = async () => {
    if (
      this.selectedZone &&
      this.selectedTransport &&
      this.selectedDuration &&
      (this.parkingCode || this.selectedZone) &&
      !this.validError
    ) {
      this.costLoaded = false;

      const startTime = this.getPostPaymentStartTime(this.paymentType, this.postPaymentStartTime);

      const response = await PaymentStore.costOfSession({
        zone: this.selectedZone,
        transport: this.selectedTransport,
        duration: this.selectedDuration,
        parkingCode: !SettingsStore.settings.paymentByParkingId
          ? ""
          : this.parkingCode,
        timestart: startTime
      });

      this.costLoaded = true;
      if (response) {
        if (response.status !== "error") {
          const costInfo = response;
          this.costError = false;
          this.costData = costInfo.costData;
          this.sumSale = costInfo.sumSale;
          this.benefitsName = costInfo.benefitsName;
          this.price = costInfo.price;
          this.priceRest = costInfo.priceRest;
          this.moneyEnough = costInfo.moneyEnough;

          this.isAlertVisible = false;

          return;
        }

        if (response.errorName !== "CostCalculationError") {
          this.costError = true;
          this.isAlertVisible = true;
          this.alertText = ErrorStore.getError(response.errorName);
          this.price = 0;

          return;
        }

        this.costError = true;
      }
    }
  };

  getPostPaymentStartTime(paymentType, startTime) {
      switch (paymentType) {
        case 'postPayment':
          return startTime;
        case 'postPaymentYesterday':
          if (startTime === null) return null;
          const yesterdayStartTime = new Date(startTime);
          yesterdayStartTime.setDate(yesterdayStartTime.getDate() - 1);
          return yesterdayStartTime;
        default:
          return null;
      }
  }

  checkPriceVisibility() {
    const isPostPaymentValid =
        (this.paymentType === "postPayment" || this.paymentType === "postPaymentYesterday")
        ? !!this.postPaymentStartTime && !this.postTimeError
        : true;

    return (
      (!this.validError || !this.costError) &&
      !!this.selectedZone &&
      !!this.selectedDuration &&
      !!this.selectedTransport &&
      isPostPaymentValid &&
      this.costLoaded
    );
  }

  isLoadError() {
    return (
      userStore.profile.error ||
      userStore.benefits.error ||
      MapStore.zones.error
    );
  }

  isAllDataFetched() {
    return (
      userStore.profile.done &&
      userStore.benefits.done &&
      MapStore.zones.done &&
      MapStore.status === "done"
    );
  }

  fetch() {
    userStore.loadProfileIfNeeded();
    userStore.loadBenefitsIfNeeded();
    MapStore.loadZonesIfNeeded();
    MapStore.loadData("parking");
  }

  getNotParkedVehicle() {
    let notParkedVehicle = [];

    userStore.getTs().forEach(transport => {
      const label = String(transport.numberTs)
        .replace(/\s/g, "")
        .replace(/^([A-zА-я])(\d{3})([A-zА-я]{2})(\d{2,3})/i, "$1 $2 $3 $4")
        .toUpperCase();

      if (!userStore.isParked(transport.numberTs))
        notParkedVehicle.push({
          label,
          value: transport.numberTs
        });
    });

    return notParkedVehicle;
  }

  setZones = zones => {
    return zones
      .map((zone, key) => {
        // Если убрать хоть один key, то вылетает warning...
        const { name } = zone;
        const { price, period } = this._getZonePriceAndPeriod(zone);

        const label = (
          <div key={"zone0" + key}>
            <span key={"zone1" + key}>
              {SettingsStore.getZoneOrParkingLabel()} #{name}
            </span>
            <span key={"zone2" + key} className="text-secondary">
              {" "}
              •{" "}
            </span>
            <span key={"zone3" + key} className="sum neutral">
              <span key={"zone4" + key}>{price}</span>
              <span key={"zone5" + key} className="rest">
                .00
              </span>
            </span>
            <span key={"zone6" + key}>
              {" "}
              {this.t("parking:payments:labels:perHour")}
            </span>
            <div key={"zone7" + key} className="work-time text-black-50">
              {period}
            </div>
          </div>
        );

        return {
          label,
          value: name,
          payload: {
            zonenumber: name
          }
        };
      })
      .sort((a, b) => {
        return String(a.value).localeCompare(String(b.value));
      });
  };

  _updateParking = () => {
    userStore.updateAll();
    this.clearAllFields();
  };

  _getZonePriceAndPeriod = zone => {
    let periodFound = false;
    let foundZone = null;
    if (typeof zone === "string") {
      foundZone = MapStore.getParkings.find(
        z => String(z.name) === String(zone) || String(z.value) === String(zone)
      );
    } else {
      foundZone = zone;
    }

    if (!foundZone) {
      return {
        price: 0,
        period: null
      };
    }

    const { prices } = foundZone;

    const today = (() => {
      const day = moment().isoWeekday();

      if (day === 6) {
        return "saturday";
      }

      if (day === 7) {
        return "sunday";
      }

      return "default";
    })();

    prices.forEach(period => {
      if (periodFound) {
        return;
      }

      if (period.type === today) {
        const { from, to } = period.interval;

        const start = moment(from, "HH:mm:ss");
        const end = moment(to, "HH:mm:ss");

        if (moment().isBetween(start, end)) {
          periodFound = period;
        }
      }
    });

    if (!periodFound) {
      return {
        price: 0,
        period: null
      };
    }

    const start = moment(periodFound.interval.from, "HH:mm:ss").format("HH:mm");
    const end = moment(periodFound.interval.to, "HH:mm:ss").format("HH:mm");

    return {
      price: periodFound.price,
      period: start + " - " + end
    };
  };

  clearAllFields = () => {
    this.selectedOptionTransport = null;
    this.selectedDurationTransport = null;

    this.selectedDuration = null;
    this.selectedZone = null;
    this.selectedTransport = null;
    this.selectedRenewZone = null;
    this.selectedRenewTransport = null;
    this.renewEndDate = null;
    this.selectedParkingType = null;
    this.selectedRenewId = null;

    this.priceVisible = false;

    forIn(this._refs, ref => {
      if (ref.current) {
        ref.current.clear && ref.current.clear();
        ref.current.reset && ref.current.reset();
      }
    });
  };

  getSearchSelectOptions() {
    const selectTransportOptions = {
      label: this.t("payments:labels:transport"),
      options: this.getNotParkedVehicle(),
      onSelect: this._handleTransportSelect,
      filter: true
    };

    const inputTransportOptions = {
      name: "transport",
      id: "transport",
      label: this.t("parking:payments:labels:vehicleNumber"),
      type: "text",
      onChange: this._handleTransportSelect
    };

    return {
      selectTransportOptions,
      inputTransportOptions
    };
  }

  validatePostTime = (time, onValidateSuccess, onValidateError, postPaymentType = '') => {
    if (time === null) return false;
    if (postPaymentType === 'postPaymentYesterday' || moment(time).isSameOrBefore(moment())) {
      onValidateSuccess(time);
      return true;
    } else {
      if (time) onValidateError(time);
      return false;
    }
  };

  validateTransportField = where => {
    const input = this._refs.searchselect.current.input;
    const inputValue = this.selectedTransport.split(" ").join("");

    if (userStore.isParked(this.selectedTransport.toUpperCase())) {
      input.current.setErrorState(
        false,
        this.t("profile:transports:edit:errors:parked")
      );
      this.validError = true;
      this.priceVisible = false;

      return;
    }
    if (!inputValue) {
      input.current.setErrorState(
        false,
        this.t("profile:transports:edit:errors:notEmpty")
      );
      this.validError = true;
      this.priceVisible = false;
      return;
    }

    const car = userStore.getCarByNumber(this.selectedTransport);
    const format = car ? car.formatGrz : !userStore.GRZ_FORMATS.RUSSIA;
    const isForeign = userStore.getGrzFormat(format);

    if (
      !isForeign &&
      !CarNumberStore.validateNumberByType(this.selectedTransport).isValid
    ) {
      input.current.setErrorState(
        false,
        this.t("profile:transports:edit:errors:format")
      );
      this.validError = true;
      this.priceVisible = false;
      return;
    }

    this.validError = false;
    input.current.setErrorState(true, "");
  };

  getParkings() {
    return MapStore.getParkingsWithoutFree();
  }
}
