import React from 'react';
import SettingsStore from './../../../../../Stores/SettingsStore';
import FontAwesome from '@fortawesome/react-fontawesome';
import car from '@fortawesome/fontawesome-free-solid/faCar';
import Moment from 'moment';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import faRuble from '@fortawesome/fontawesome-free-solid/faRubleSign';

const Sessions = ({ t, sessions, button = () => null }) => {
  if (sessions && sessions.length > 0) {
    const Button = button;

    return (
      <div className="sessions-list">
        {sessions.map((session, index) => (
          <div className="row list-item" key={'row-' + index}>
            <div className="d-flex flex-column justify-content-center">
              <span className="text-black-50" style={{ marginRight: 15 }}>
                {SettingsStore.getZoneOrParkingLabel()}#{session.zone}
              </span>

              <span>
                <FontAwesome icon={car} />
                <span style={{ marginLeft: 5 }}>{session.transport.numberTs}</span>
              </span>

              <div className="text-secondary" style={{ marginBottom: 5 }}>
                {t('parking:currentParkings:startedAt')}:{' '}
                {Moment.utc(session.startDate)
                  .local()
                  .format('DD MMMM HH:mm')}
              </div>
              {session.status === 'waiting' ? (
                <div
                  className="text-secondary d-flex align-items-center"
                  style={{ marginBottom: 10 }}
                >
                  Сумма списания: {session.amountToPay}{' '}
                  <FontAwesomeIcon icon={faRuble} style={{ fontSize: 12, fontWeight: 100 }} />
                </div>
              ) : null}
            </div>
            <div className="d-flex buttons">
              <Button session={session} t={t} />
            </div>
          </div>
        ))}
      </div>
    );
  }

  return <p>{t('parking:currentParkings:noParkingMessage')}</p>;
};

export default Sessions;
