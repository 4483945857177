import operationsHistory from '../dicts/operationsHistory';
import profile from '../dicts/profile';
import parking from '../dicts/parking';
import operation from '../dicts/operation';
import filter from '../dicts/filter';
import loginForm from '../dicts/loginForm';
import registrationForm from '../dicts/registrationForm';
import abonements from '../dicts/abonements';
import reservation from '../dicts/reservation';
import notFound from '../dicts/notFound';
import restorePassword from '../dicts/restorePassword';
import cloudPayments from '../dicts/cloudPayments';
import errors from '../dicts/errors';
import exportTr from '../dicts/export';
import dates from '../dicts/dates';
import penalty from '../dicts/penalty';

const en = {
  phone: { label: 'Phone' },
  sideMenu: {
    parkingMap: 'Parking map',
  },
  auth: {
    registration: 'SignUp',
    login: 'LogIn',
    logout: 'LogOut',
    tokenExpired: 'The session has expired. Please sign in again.',
  },
  changeCity: {
    selectCityFromList: 'Select your city from list',
    confirmButton: 'Confirm',
    inputPlaceholder: 'City',
    changeCity: 'Change city',
  },
  header: {
    logoTitle: {
      belgorod: 'Belgorod',
      full: 'Sevastopol Parking Hub',
      parkingSpace: 'Parking Hub',
      sevastopol: 'Sevastopol',
      voronezh: 'Voronezh',
      kazan: 'Kazan',
      spb: 'Leningrad',
      krasnogorsk: 'Krasnogorsk',
      rostov: 'Rostov-on-Don',
      tyumen: 'Tyumen parking space',
      cheboksary: 'Cheboksary',
      tver: 'Tver',
      penza: 'Penza',
      novorossiysk: 'Novorossiysk',
      yoshkarola: 'Yoshkar-Ola',
      yalta: 'Yalta',
      vlc: 'Vladivostok',
    },
    balance: {
      balance: 'Balance',
      refill: 'Top Up',
    },
    parkingInfo: {
      zone: 'Parking zone',
      number: 'License Plate',
      parking: 'Parking',
      allParking: 'All parkings',
      startParking: 'Start parking',
      infoNotFound: 'No parking reserved',
      from: 'From',
      to: 'till',
      left: 'Time left',
    },
  },
  footer: {
    ourApp: 'Our Apps',
  },
  map: {
    searchField: 'Address',
  },
  filter: {
    ...filter.eng,
  },
  reservation: {
    ...reservation.eng,
  },
  profile: {
    ...profile.eng,
  },
  parking: {
    ...parking.eng,
  },
  operations: {
    ...operation.eng,
  },
  operationsHistory: {
    ...operationsHistory.eng,
  },
  loginForm: {
    ...loginForm.eng,
  },
  registrationForm: {
    ...registrationForm.eng,
  },
  abonements: {
    ...abonements.eng,
  },
  notFound: {
    ...notFound.eng,
  },
  restorePassword: {
    ...restorePassword.eng,
  },
  cloudPayments: {
    ...cloudPayments.en,
  },
  errors: {
    ...errors.en,
  },
  export: {
    ...exportTr.en,
  },
  dates: {
    ...dates.en,
  },
  smsCommands: {
    title: 'Sms comands',
    sendTo: 'Sms with comand send to',
  },
  penalty: {
    ...penalty.en,
  },
  feedback: {
    title: 'Feedback',
  },
};

export default en;
