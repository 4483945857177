import { observable, action } from "mobx";
import APIProvider from "./APIProvider";

class AbonementStore {
  TYPESRU = {
    PREV_DAY: "Предыдущий день",
    DAY: "День",
    WEEK: "Неделя",
    MOUNTH: "Месяц",
    QUARTER: "Квартал",
    YEAR: "Год"
  };

  TYPES = {
    PREV_DAY: "prev_day",
    DAY: "day",
    WEEK: "week",
    MOUNTH: "mounth",
    QUARTER: "quarter",
    YEAR: "year"
  };

  PROPERTIESRU = {
    PARKING_PERMIT: "ПарковочноеРазрешение",
    PARKING_PERMIT_CODE: "КодПарковочногоРазрешения",
    PARKING_PERMIT_TYPE: "ТипПарковочногоРазрешения",
    ZONES: "Зоны",
    COST: "Цена"
  };

  PROPERTIES = {
    PARKING_PERMIT: "parkingPermit",
    PARKING_PERMIT_CODE: "parkingPermitCode",
    PARKING_PERMIT_TYPE: "parkingPermitType",
    ZONES: "zones",
    COST: "cost"
  };

  @observable abonements = [];

  @observable status = {
    isLoading: false,
    done: false,
    error: {
      status: false,
      message: ""
    }
  };

  loadSubscriptionsIfNeeded() {
    if (this.abonements.length === 0) {
      this.loadSubscriptions();
    }
  }

  @action
  async loadSubscriptions() {
    this.status.isLoading = true;
    const result = await APIProvider.getSubscription();
    const normalizedSubscriptions = this.normalizeSubscriptions(result.result);
    this.abonements = normalizedSubscriptions;

    this.status.isLoading = false;
    this.done = true;
  }

  normalizeSubscriptions(subscriptions) {
    if (Array.isArray(subscriptions))
      return subscriptions.map(subscription => ({
        parkingPermit: subscription[this.PROPERTIESRU.PARKING_PERMIT],
        parkingPermitCode: subscription[this.PROPERTIESRU.PARKING_PERMIT_CODE],
        parkingPermitType: this.normalizeType(
          subscription[this.PROPERTIESRU.PARKING_PERMIT_TYPE]
        ),
        cost: subscription[this.PROPERTIESRU.COST],
        zone: subscription[this.PROPERTIESRU.ZONES] || "all"
      }));

    return [];
  }

  normalizeType(subscriptionType) {
    switch (subscriptionType) {
      case this.TYPESRU.PREV_DAY:
        return this.TYPES.PREV_DAY;
      case this.TYPESRU.DAY:
        return this.TYPES.DAY;
      case this.TYPESRU.WEEK:
        return this.TYPES.WEEK;
      case this.TYPESRU.MOUNTH:
        return this.TYPES.MOUNTH;
      case this.TYPESRU.QUARTER:
        return this.TYPES.QUARTER;
      case this.TYPESRU.YEAR:
        return this.TYPES.YEAR;
      default:
        return "";
    }
  }
}

export default new AbonementStore();
