const operationsEng = {
  edit: 'Edit',
  add: 'Add',
  abort: 'Cancel',
  annulment: 'Cancel',
  back: 'Back',
  buySubscription: 'Buy subscription',
  cancle: 'Cancel',
  change: 'Change',
  continue: 'Continue',
  code: 'Code',
  delete: 'Delete',
  downloadCsv: 'Download CSV',
  downloadXls: 'Download XLS',
  downloadPdf: 'Download PDF',
  extend: 'Extend',
  recharge: 'Top Up',
  findOutPrice: 'Price range',
  from: 'from',
  to: 'till',
  startParking: 'Start parking',
  payForParking: 'Pay fro parking',
  getPrice: 'Get price',
  payment: 'Payment',
  refill: 'Top Up',
  refund: 'Refund',
  bonus20: 'Bonus 20%',
  paymentSubscription: 'Subscription purchase',
  sendCode: 'Send code',
  sendCodeAgain: 'Resend code',
  send: 'Send',
  save: 'Save',
  showMore: 'Show more',
  cancel: 'Close',
  exit: 'Exit',
  extension: 'Extension',
  makePrimary: 'Make primary',
  payWithout: 'Pay without',
  registration: 'registration',
  backToHome: 'Back to main page',
  export: 'Export',
  paymentGate: 'Pay for planar parking'
};

const operationsRu = {
  edit: 'Редактировать',
  add: 'Добавить',
  abort: 'Прервать',
  back: 'Назад',
  buySubscription: 'Покупка абонемента',
  annulment: 'Отмена',
  cancle: 'Перрывание',
  change: 'Изменить',
  continue: 'Продолжить',
  code: 'Код',
  delete: 'Удалить',
  downloadCsv: 'Скачать CSV',
  downloadXls: 'Скачать XLS',
  downloadPdf: 'Скачать PDF',
  extend: 'Продлить',
  recharge: 'Пополнить',
  findOutPrice: 'Узнать цену',
  from: 'с',
  to: 'до',
  startParking: 'Начать парковку',
  payForParking: 'Оплатить парковку',
  getPrice: 'Узнать цену',
  pay: 'Оплатить',
  payment: 'Оплата',
  refill: 'Пополнение',
  refund: 'Возврат',
  bonus20: 'Бонус 20% за оплату картой МИР',
  paymentSubscription: 'Покупка абонемента',
  sendCode: 'Отправить код',
  sendCodeAgain: 'Отправить код снова',
  send: 'Отправить',
  save: 'Сохранить',
  showMore: 'Показать еще',
  cancel: 'Закрыть',
  exit: 'Выйти',
  extension: 'Продление',
  makePrimary: 'Сделать основным',
  payWithout: 'Оплатить без',
  registration: 'регистрации',
  backToHome: 'Вернуться на главную',
  export: 'Экспорт',
  paymentGate: 'Оплата плоскостной парковки'
};

export default {
  eng: operationsEng,
  ru: operationsRu
};
