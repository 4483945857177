import React from 'react';
import * as Yup from 'yup';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '../../common/FormControl';
import { useFormik, yupToFormErrors } from 'formik';
import Button from 'reactstrap/lib/Button';
import Alert from 'reactstrap/lib/Alert';
import isEmpty from 'lodash/isEmpty';

import {
  FormikSelect,
  TsSelect as FormikAutoSelect,
  FormikInput,
} from '../../../../Common/Inputs';
import SpinnerButton from '../../../../Common/Buttons/SpinnerButton';
import AutoSubmit from '../../../../Common/Inputs/AutoSubmit';
import {
  MODAL_PAYMENT_BANNER_IMG_URL,
  APP_MODAL_BANNER_REDIRECT_URL,
} from '../../../../../Config';

const paymentBannerStyle = {
  display: 'block',
  width: '100%',
  paddingBottom: '13%',
  backgroundColor: 'transparent',
  marginBottom: '15px',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundImage: `url(${MODAL_PAYMENT_BANNER_IMG_URL})`,
};

const RefillForm = ({
  t,
  onAbort,
  services = [],
  phones = [],
  onGetPrice = () => null,
  onChange = () => null,
  toPaymentService = null,
  onSubmit = () => null,
  closeModal,
  gettingPrice,
}) => {
  const submitRef = React.useRef(null);

  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      amount: 0,
      service: services.length > 0 ? services[0] : null,
      phone: phones.length > 0 ? phones[0] : null,
    },
    // Validation phase
    validate: (values) => {
      const phoneRegExp = /^((\+7|7|8)+([0-9]){10})$/;

      const schema = Yup.object().shape({
        amount: Yup.number()
          .required(t('errors:RequiredField'))
          .typeError(t('errors:ItNeedToBeANumber'))
          .moreThan(0, t('Сумма должна быть больше нуля')),
        service: Yup.object()
          .nullable()
          .required(t('errors:RequiredField')),
        phone: Yup.object().when('service', {
          is: (val) => val && val.isPhoneRequired,
          then: Yup.object().shape({
            phone: Yup.string()
              .required(t('errors:RequiredField'))
              .matches(phoneRegExp, t('errors:InvalidFormat')),
            isMain: Yup.bool(),
          }),
          otherwise: Yup.object().nullable(),
        }),
      });

      // Catch Yup errors
      try {
        schema.validateSync(values);
      } catch (validationError) {
        return yupToFormErrors(validationError);
      }
      return {};
    },
    // Submit phase
    onSubmit: async (values, { resetForm, setStatus }) => {
      const submitElement = submitRef.current;
      if (submitElement) {
        submitElement.click();
        window.open(toPaymentService, '_blank');
      }

      if (onSubmit) {
        const response = await onSubmit(values);
        if (response) {
          if (response.status !== 'ok') {
            setStatus(`errors:${response.errorName}`);
          } else {
            setStatus(null);
          }
        }
      }

      resetForm({});
    },
  });

  const handleAbort = React.useCallback(() => {
    if (onAbort) {
      onAbort();
    }
  });

  const { values, status, isValid, errors, setStatus, setSubmitting } = formik;

  React.useEffect(() => {
    if (submitRef && submitRef.current) {
      submitRef.current.href = toPaymentService;
    }
  });

  return (
    <>
      {MODAL_PAYMENT_BANNER_IMG_URL !== '' ? (
        <a
          onClick={closeModal}
          href={APP_MODAL_BANNER_REDIRECT_URL}
          style={paymentBannerStyle}
          target="_blank"
        ></a>
      ) : null}
      <form onSubmit={(e) => e.preventDefault()}>
        {status ? <Alert color="danger">{status}</Alert> : null}
        <FormControl fullWidth>
          <FormikInput
            name="amount"
            label="Сумма поплнения"
            form={formik}
            field={formik.getFieldProps({ name: 'amount' })}
          />
        </FormControl>
        <FormControl fullWidth>
          <FormikSelect
            form={formik}
            field={formik.getFieldProps({ name: 'service' })}
            label="Методы оплаты"
            name="service"
            items={services}
            renderValue={(value) => {
              return value.methodNameForShow;
            }}
            renderItems={(items) =>
              items.map((item) => (
                <MenuItem key={item.methodNameForShow} value={item}>
                  <span>
                    <span>{item.methodNameForShow}</span>
                    <div className="text-secondary">
                      {item.methodDescription}
                    </div>
                  </span>
                </MenuItem>
              ))
            }
          />
        </FormControl>
        <FormControl fullWidth>
          {values.service && values.service.isPhoneRequired ? (
            <FormikAutoSelect
              form={formik}
              field={formik.getFieldProps({ name: 'phone' })}
              label="Номер телефона"
              name="phone"
              options={phones}
              clearOnEscape={false}
              disableClearable
              noOptionsText="Добавьте номер телефона в профиль"
              getOptionLabel={(phone) => phone.phone}
            />
          ) : null}
        </FormControl>
        <FormControl fullWidth>
          <p>
            Вы будете перенаправлены на страницу платёжной системы, где сможете
            произвести оплату.{' '}
          </p>
        </FormControl>
        {!toPaymentService ? (
          <SpinnerButton
            tag={'button'}
            color="primary"
            type="submit"
            className="submit"
            target="_blank"
            innerRef={submitRef}
            style={{ appearance: 'none' }}
            isLoading={gettingPrice}
            disabled={!isValid || gettingPrice}
            onClick={() => onGetPrice(values)}
          >
            Получить ссылку на пополнение
          </SpinnerButton>
        ) : (
          <SpinnerButton
            tag={'a'}
            color="primary"
            className="submit"
            target="_blank"
            innerRef={submitRef}
            style={{ appearance: 'none' }}
            disabled={!toPaymentService}
            onClick={() => onSubmit(values)}
          >
            {t('operations:pay')}
          </SpinnerButton>
        )}
        <Button
          outline
          color="primary"
          onClick={handleAbort}
          className="cancel"
        >
          {t('operations:annulment')}
        </Button>
        <AutoSubmit
          t={t}
          submitForm={onChange}
          values={values}
          isValid={isValid && isEmpty(errors)}
          setSubmitting={setSubmitting}
          setStatus={setStatus}
        />
      </form>
    </>
  );
};

export default RefillForm;
