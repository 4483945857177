import { observable, action, computed } from 'mobx';
import { create, persist } from 'mobx-persist';

import APIProvider from './APIProvider';
import UserStore from './UserStore';

const hydrate = create({});

class RegistrationStore {
  @observable hydrated = false;

  @persist @observable firstname = null;
  @persist @observable lastname = null;
  @persist @observable middlename = null;
  @persist @observable pass = null;

  @persist @observable email = null;
  @persist @observable emailSalt = null;
  @persist @observable emailCode = null;

  @persist @observable phone = null;
  @persist @observable phoneSalt = null;

  @persist @observable confirmationType = null;
  @persist @observable canSendCodeAgain = true;

  @observable isConfirmationTypeLoaded = false;

  @action
  hydrateCompleted() {
    this.hydrated = true;
  }

  @action
  finish(token, userData) {
    this.clear();

    UserStore.setToken(token);
    UserStore.profile = {
      ...userData,
      isLoaded: true
    };
  }

  @action
  registration = async profileData => {
    const token = await APIProvider.registration(profileData);
    if (token) {
      this.email = profileData.email;
      this.firstname = profileData.firstname;
      this.lastname = profileData.lastname;
      this.middlename = profileData.middlename;
      this.pass = profileData.pass;
      this.phone = profileData.phone;
      this.emailSalt = token;
    }

    return token;
  };

  @action
  checkType = async () => {
    const response = await APIProvider.getConfirmationType();
    this.isConfirmationTypeLoaded = true;
    if (response.status === 'ok') {
      this.confirmationType = response.result.typeAuthorization;
    } else {
      this.confirmationType = null;
    }
  }

  @action
  disableAgainSendCode = async () => {
    this.canSendCodeAgain = false;
  }

  getRegistrationData() {
    const { email, firstname, lastname, middlename, pass, phone } = this;

    return {
      email,
      firstname,
      lastname,
      middlename,
      pass,
      phone
    };
  }

  @action
  async changePhone(newPhone) {
    const response = await APIProvider.changePhoneInRegistration(newPhone, this.emailSalt);

    if (response.status === 'ok') {
      this.phone = newPhone;
    }

    return newPhone;
  }

  @action
  clear() {
    this.email = null;
    this.emailSalt = null;

    this.firstname = null;
    this.lastname = null;
    this.middlename = null;
    this.pass = null;

    this.phone = null;
    this.phoneSalt = null;

    this.canSendCodeAgain = true;
    this.confirmationType = null;
    this.isConfirmationTypeLoaded = false;
  }

  @computed
  get isSmsConfirmation() {
    return this.confirmationType === 'SMS';
  }

  @computed
  get isConfirmationTypeDefined() {
    return ['SMS', 'Call'].includes(this.confirmationType);
  }
}

const registrationStore = new RegistrationStore();

hydrate('registrationStore', registrationStore)
  .then(async () => {
    await registrationStore.hydrateCompleted();
  })
  .catch(console.log);

export default registrationStore;
