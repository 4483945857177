import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import AppContainer from './Containers/AppContainer';
import { persistance } from './Stores/NotificationStore';
// import Route from './Containers/Routes/RootLngRoute/RootLngRoute';

class App extends Component {
  render() {
    persistance();
    return (
      <>
        <Switch>
          <Route exact path="/" component={AppContainer} />
          <Route path="/:lng" component={AppContainer} />
        </Switch>
      </>
    );
  }
}

export default App;
