const restoreEng = {
  stepsNames: {
    step: 'Step',
    sendEmail: 'Send email',
    confirmEmail: 'Confirm email',
    changePassword: 'Change password',
    easyFirst: 'Enter your e-mail or phone number',
    easySecond: 'Get your new password'
  },
  steps: {
    step1: {
      formFields: {
        email: 'Email'
      },
      checkingInfo: 'Please wait we are checking your data',
      mainText: 'Enter your email. Within a few minutes' + 'a confirmation email will be sent ',
      mainTextForCall:
        'Введите номер своего мобильного телефона. В течение нескольких минут вам поступит звонок. ' +
        'Последние 4 цифры входящего номера являются вашим новым паролем.',
      easyMainText:
        'Enter your email or phone number. Within a few minutes' +
        'a message with new password will be sent to it',
      submitButton: 'Send code',
      errors: {
        emptyField: 'You need to fill in this field',
        incorrectEmail: 'Incorrect email address entered',
        userWithEmailExist: 'User with this email already exists',
        passwordLength: 'The password must be longer than 3 characters',
        confirmPassword: 'Repeat the password',
        passwordsNotMatch: 'The passwords do not match',
        requiredField: 'This field is required',
        incorrectNumber: 'Incorrect phone number entered',
        userAgreement: 'To continue the registration you need to accept the user agreement',
        validateError: 'This field cannot be validated',
        registrationError: 'Registration error. Please try again later',
        emptyPass:
          'Attention! Due to the system upgrade, you need to update your password to continue.',
        unknownEmail: 'User with this email is not found.'
      }
    },
    step2: {
      mainText: 'The email has been successfully sent. Follow the link from the email.',
      easyMainTextPhone: 'Your new password has send to your phone',
      easyMainTextEmail: 'Your new password has send to your E-mail'
    },
    step3: {
      formFields: {
        token: 'Code',
        password: 'Password'
      },
      mainText: 'New password',
      repeat: 'Repeat password',
      submitButton: 'Send',
      phoneMessage: {
        smsSent: 'A text with the code is successfully sent',
        enterBelow: 'Enter the code in the box below'
      },
      waitingMessage: 'Please wait',
      sendCodeAgain: {
        message: 'Send the coode again in',
        button: 'Send the code again'
      },
      errors: {
        incorrectCode: 'The code is incorrect',
        enterCode: 'Please enter the code',
        passwordsDidntMatch: 'Passwords do not match',
        strongValidate: 'The password must be between 6 and 20 characters long'
      },
      modal: {
        title: 'Change password',
        text: 'Password change is successful.',
        buttonText: 'LogIn'
      }
    }
  }
};
const restoreRu = {
  stepsNames: {
    step: 'Шаг',
    sendEmail: 'Отправить код',
    confirmEmail: 'Подтверждение',
    changePassword: 'Изменение пароля',
    easyFirst: 'Введите свои E-mail или номер телефона',
    easySecond: 'Получите новый пароль'
  },
  steps: {
    step1: {
      formFields: {
        email: 'Email'
      },
      checkingInfo: 'Пожалуйста подождите, мы проверяем ваши данные',
      waitingMessage: 'Пожалуйста подождите, мы отправляем письмо.',
      mainText:
        'Введите свой Email. В течении нескольких минут ' +
        'на него поступит письмо со ссылкой для подтвержения.',
      mainText:
        'Введите свой Email или мобильный номер. В течение нескольких минут ' +
        'на него поступит сообщение с новым паролем.',
      mainTextForCall:
        'Введите номер своего мобильного телефона. В течение нескольких минут вам поступит звонок. ' +
        'Последние 4 цифры входящего номера являются вашим новым паролем.',
      submitButton: 'Отправить',
      errors: {
        emptyEmail: 'Введите вашу почту',
        emptyField: 'Вам нужно заполнить это поле',
        incorrectEmail: 'Incorrect e-mail address entered',
        userWithEmailExist: 'User with this email already exists',
        passwordLength: 'Длина пароля должна быть больше 3 символов',
        confirmPassword: 'You must repeat the password',
        passwordsNotMatch: 'The passwords you entered do not match',
        requiredField: 'This field is required',
        incorrectNumber: 'Incorrect number entered',
        userAgreement: 'To continue the registration you need to accept the user agreement',
        validateError: 'You can not validate this field',
        registrationError: 'An error occurred while registering. Please try again later.',
        emptyPass:
          'Внимание! В связи с обновлением системы вы должны обновить свой пароль, чтобы продолжить.',
        unknownEmail: 'Пользователь с таким e-mail не найден.'
      }
    },
    step2: {
      mainText: 'Письмо успешно отправлено на почту. Перейдите по ссылке, указанной в нем.',
      easyMainTextPhone: 'Ваш новый пароль был успешно отправлен вам на телефон',
      easyMainTextEmail: 'Ваш новый пароль был успешно отправлен вам на потчу'
    },
    step3: {
      formFields: {
        token: 'Код',
        password: 'Пароль'
      },
      mainText: 'Введите новый пароль',
      repeat: 'Повторите пароль',
      submitButton: 'Отправить',
      phoneMessage: {
        smsSent: 'SMS with code successfully sent to number',
        enterBelow: 'You need to enter it in the box below'
      },
      waitingMessage: 'Пожалуйста подождите',
      sendCodeAgain: {
        message: 'Send again you can after',
        button: 'Send code again'
      },
      errors: {
        incorrectCode: 'Некорректный код',
        enterCode: 'Пожалуйста, введите код',
        passwordsDidntMatch: 'Пароли не совпадают',
        minLength: 'The minimum password length is 3 characters',
        strongValidate: 'Длина пароля должа быть от 6 до 20 символов'
      },
      modal: {
        title: 'Изменение пароля',
        text: 'Изменение пароля прошло успешно.',
        buttonText: 'Войти'
      }
    }
  }
};

export default {
  eng: restoreEng,
  ru: restoreRu
};
