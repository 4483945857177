import React, { Component } from 'react';

import { Container } from 'reactstrap';

import Login from '../views/LoginFactory';
import LoginStore from '../../../Stores/LoginStore';

import { observer } from 'mobx-react';
import '../../../Assets/Styles/Registration/Compiled/main.css';
import userStore from '../../../Stores/UserStore';
import CoopUserStore from '../../../Stores/CoopUser';
import PathStore from '../../../Stores/PathStore';

import { IS_EASY_LOGIN } from '../../../Config';

@observer
class LoginContainer extends Component {
  render() {
    return (
      <Container fluid>
        <Login
          easy={IS_EASY_LOGIN}
          logIn={this.logIn}
          coopLogIn={this.coopLogIn}
          status={LoginStore.status}
          error={LoginStore.error}
          setTokenToUser={this.setTokenToUser}
          setTokenToCoopUser={this.setTokenToCoopUser}
          goToMainPage={this.goToMainPage}
          isLoggedIn={this.isLoggedIn}
          loadProfile={this.loadProfile}
          history={this.props.history}
        />
      </Container>
    );
  }

  logIn = (email, password, captchaCode = '') => {
    const { location, history } = this.props;
    if (location.pathname.search('registration') !== -1) {
      history.push('/');
    }

    return LoginStore.logIn(email, password, captchaCode);
  };

  coopLogIn = async (inn, contractNumber, captchaCode = '') => {
    const { history } = this.props;
    const response = await LoginStore.coopLogIn(inn, contractNumber, captchaCode);

    if (response) {
      history.push(PathStore.pathWithLang('/coop-profile'));
    }

    return response;
  };

  setTokenToUser = token => {
    userStore.setToken(token);
  };

  setTokenToCoopUser = token => {
    CoopUserStore.setToken(token);
  };

  loadProfile = () => {
    userStore.updateProfile();
  };

  goToMainPage = () => {
    this.props.history.push('/');
  };

  isLoggedIn() {
    return userStore.isLoggedIn();
  }
}

export default LoginContainer;
