import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router';
import { Route, Switch, Redirect } from 'react-router-dom';

import HeaderContainer from '../../Containers/HeaderContainer';
import FooterContainer from '../../Containers/FooterContainer/FooterContainer';
import Login from '../../Containers/Login/container/LoginContainer';
import SideMenuContainer from '../../Containers/MenuSideContainer/index';

import ProgressLine from '../../Components/ProgressLine/ProgressLine';
import Router from '../../Router';
import SimpleModal from '../../Components/SimpleModal';
import SelectCityModal from '../../Components/SelectCityModal';

import { Container, Row } from 'reactstrap';

import UserStore from '../../Stores/UserStore';
import OptionsStore from '../../Stores/OptionsStore';
// eslint-disable-next-line
import PolyfillService from '../../utils/Polyfills';

import 'moment/locale/ru';

import menuFields from '../../fakeData/headerMenu';
import { coopyright } from '../../fakeData/footer/others';
import { aboutUs, apps } from '../../fakeData/footer/aboutUs';

import '../../Assets/Styles/MaterialDesign/Compiled/index.css';

import '../../Assets/Styles/Common/fonts.css';
import '../../Assets/Styles/Common/dropdown.css';
import '../../Assets/Styles/Common/reset.css';
import '../../Assets/Styles/Common/btns.css';
import '../../Assets/Styles/Common/theming.scss';

const HeaderWithRouter = withRouter(HeaderContainer);
const FooterWithRouter = withRouter(FooterContainer);
const LoginWithRouter = withRouter(Login);
const SimpleModalWithRouter = withRouter(SimpleModal);

@observer
// @withCitySelector
class App extends Component {
  constructor(props) {
    super(props);
    this.logout = this.logout.bind(this);
    this.initPaymentCallbackInfo();
  }

  render() {
    const isMainPage =
      this.props.location.pathname === '/' ||
      this.props.location.pathname === '/en' ||
      this.props.location.pathname === '/ru' ||
      this.props.location.pathname === '/en/' ||
      this.props.location.pathname === '/ru/';
    const isRuPage = this.props.location.pathname.split('/').includes('ru');
    const containerMargin = !isMainPage && ' mt-header';
    return (
      <Fragment>
        <SideMenuContainer
          menuFields={menuFields}
          aboutUs={aboutUs}
          apps={apps}
          coopyright={coopyright}
          tel={OptionsStore.contacts.phone}
          email={OptionsStore.contacts.email}
          logout={this.logout}
        />
        <LoginWithRouter />
        <HeaderWithRouter />
        <SelectCityModal />
        <ProgressLine />
        <SimpleModalWithRouter />
        <div
          className={`d-flex ${containerMargin}`}
          style={{ flex: '1 0 auto' }}
        >
          <Row noGutters style={{ width: '100%' }}>
            <Switch>
              <Route exact path={'/'} component={Router} />

              {isRuPage ? (
                <Redirect
                  to={
                    this.props.location.pathname
                      .split('/')
                      .filter((catalog) => catalog !== 'ru')
                      .join('/') + this.props.location.search
                  }
                />
              ) : (
                <Route path={'/:lng'} component={Router} />
              )}
            </Switch>
          </Row>
        </div>
        <FooterWithRouter fixed={isMainPage} />
      </Fragment>
    );
  }

  initPaymentCallbackInfo = () => {
    localStorage.setItem('isWebPayment', true);
  };

  logout() {
    UserStore.removeToken();
    this.props.history.push('/');
  }
}

export default withRouter(App);
