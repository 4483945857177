const profileEng = {
  menu: {
    parking: 'Parking',
    parkingSessions: 'Parking sessions',
    operations: 'Operations history',
    abonements: 'Subscriptions and privileges',
    profile: 'Profile',
    penalty: 'Penalty'
  },
  mainInfo: {
    title: 'Basic information',
    fullName: 'Full name',
    phone: 'Phone number',
    phones: 'Phones',
    successPassEdit: 'Password has been successfully changed',
    successEmailEdit: 'E-mail has been successfully changed',
    successPhoneEdit: 'Phone has been successfully changed',
    successPhoneAdd: 'Phone has been successfully added',
    emailUndefined: 'Email не указан',
    primary: 'Primary',

    edit: {
      editItems: {
        email: 'Change email',
        phone: 'Change phone number',
        password: 'Change password'
      },

      editProfile: {
        title: 'Edit profile',

        phone: 'Phone number',
        firstName: 'First name',
        lastName: 'Last name',
        patronymic: 'Patronymic'
      },

      editEmail: {
        newEmail: 'New email',
        message: 'A confirmation code will be sent to your email.',
        secondMessage:
          'Verification email has been' + ' successfully sent.' + ' Please enter the code below'
      },

      editPhoneNumber: {
        UserExists: 'User with that phone number already exists',
        SMSNotSend: 'Failed to send message',
        newPhoneNumber: 'New phone number',
        message: 'A confirmation code will be sent to your phone number.',
        secondMessage: 'A text with the code is successfully sent' + ' Please enter the code below',
        doublePhone: 'This phone already used',
        LimitSMS: 'Limit SMS sending. Please, try again later.',
        unknownError: 'Unknown error',
        removePhoneModalText: 'Are you sure you want to remove the phone?',
        incorrectCode: 'Incorrect code',
        confirm: 'Confirm',
        abort: 'Abort',
        add: 'Add'
      },

      editPassword: {
        oldPassword: 'Previous password',
        newPassword: 'New password',
        repeatPassword: 'Repeat password',
        incorrectPassword: 'Incorrect password'
      }
    }
  },
  settings: {
    title: 'Settings',
    reportEmail: 'Monthly email reports',
  },
  transports: {
    title: 'Vehicles',
    foreign: 'Foreign number',
    anotherTS: 'Other (foreign) license plate',
    category: 'Category',

    sts: 'Certificate of vehicle registration details',
    emptyList: 'No vehicles',

    edit: {
      addTM: {
        title: 'Add vehicle'
      },
      editTM: {
        title: 'Edit vehicle'
      },
      deleteTM: {
        title: 'Delete vehicle',
        message: (number, region) => {
          return {
            __html: `Are you sure you want to delete the <em>number</em>
                                        <strong>${number} ${region}</strong>
                                         from the list of vehicles?`
          };
        }
      },
      labels: {
        name: 'Name (Not necessary)',
        registrationNumber: 'Registration number'
      },
      errors: {
        notEmpty: 'This field cannot be empty',
        format: 'Licinse plate should be A or AA 000 00 А000АА000 type',
        occupied: 'This vehicle is already on the list',
        parked: 'This vehicle is already parked',
        actionError: 'Operation failed',
        maxLength: 'The maximum length of the number is 9 characters'
      }
    }
  },
  documents: {
    title: 'Documents',
    emptyList: 'No documents',
    sertificates: {
      driverLicense: 'Driver license',
      vehicleCertificate: 'Certificate of vehicle registration'
    },

    edit: {
      addDoc: {
        title: 'Add document'
      },
      editDoc: {
        title: 'Edit document'
      },
      deleteDoс: {
        title: 'Delete document',
        message: (typeLabel, title) => {
          return {
            __html: `Do you really want to delete <em>"${typeLabel}" </em>
                                    <strong>${title}</strong> from the list of documents?`
          };
        }
      },
      errors: {
        notEmpty: 'This field is required',
        docType: 'Select a document type',
        stsFormat: 'Certificate of vehicle registration should be 00 XX 000000 type',
        dlFormat: 'Driving license should be 00 XX 000000 or 00 00 000000 type',
        occupied: 'This document already exists',
        validError: 'This field cannot be validated'
      }
    },

    labels: {
      documentType: 'Document type',
      seriesAndNumber: 'Document details'
    }
  },
  balance: {
    title: 'Balance',
    message: 'You balance is',
    currency: {
      one: 'RUB',
      two: 'RUB',
      three: 'RUB'
    }
  },

  error: {
    loadError: 'Error loading data.',
    loadProfileError: 'Error loading profile data',
    error: 'Error',
    tryAgain: 'Try again'
  },

  exit: {
    title: 'Log Out'
  }
};

const profileRu = {
  menu: {
    parking: 'Парковки',
    operations: 'История операций',
    abonements: 'Абонементы/Льготы',
    profile: 'Профиль',
    parkingSessions: 'Парковочные сессии',
    penalty: 'Штрафы'
  },
  mainInfo: {
    title: 'Основная информация',
    fullName: 'ФИО',
    phone: 'Телефон',
    phones: 'Телефоны',
    successPassEdit: 'Пароль успешно изменен',
    successEmailEdit: 'Почта успешно изменена',
    successPhoneEdit: 'Телефон успешно изменен',
    successPhoneAdd: 'Телефон успешно добавлен',
    primary: 'Основной',
    emailUndefined: 'Email не указан',
    edit: {
      editItems: {
        email: 'Изменить e-mail',
        phone: 'Изменить номер телефона',
        password: 'Изменить пароль'
      },

      editProfile: {
        title: 'Редактирование профиля',

        phone: 'Номер телефона',
        firstName: 'Имя',
        lastName: 'Фамилия',
        patronymic: 'Отчество'
      },

      editEmail: {
        newEmail: 'Новый E-mail',
        message: 'На указанный Вами E-mail адрес будет выслан код подтверждения.',
        secondMessage:
          'Письмо с кодом подтверждения успешно отправлено' +
          ' на указанную Вами почту.' +
          ' Пожалуйста, введите полученный код ниже'
      },

      editPhoneNumber: {
        UserExists: 'Пользователь с таким номером телефона уже существует',
        SMSNotSend: 'Не удалось отправить сообщение',
        newPhoneNumber: 'Номер телефона',
        message: 'На указанный Вами номер телефона будет выслан код подтверждения.',
        secondMessage:
          'СМС с кодом подтверждения успешно отправлено на указанный Вами' +
          ' телефон.' +
          ' Пожалуйста, введите полученный код ниже',
        doublePhone: 'Данный телефон уже добавлен',
        LimitSMS: 'Достигнут лимит отправки СМС. Повторите позднее.',
        unknownError: 'Неизвестная ошибка',
        removePhoneModalText: 'Вы уверены, что хотите удалить телефон?',
        incorrectCode: 'Неверный код',
        confirm: 'Подтвердить',
        abort: 'Отмена',
        add: 'Добавить'
      },

      editPassword: {
        oldPassword: 'Старый пароль',
        newPassword: 'Новый пароль',
        repeatPassword: 'Повторите пароль',
        incorrectPassword: 'Неверный пароль'
      }
    }
  },
  settings: {
    title: 'Настройки',
    reportEmail: 'Ежемесячные отчеты на email',
  },
  transports: {
    title: 'Транспортные средства',
    sts: 'Серия и номер СТС',
    category: 'Категория',
    anotherTS: 'Прочий (иностранный) номер ТС',
    foreign: 'Иностранный номер',
    emptyList: 'Список транспортных средств пока пуст.',

    edit: {
      addTM: {
        title: 'Добавить ТС'
      },
      editTM: {
        title: 'Редактировать ТС'
      },
      deleteTM: {
        title: 'Удалить ТС',
        message: (number, region) => {
          return {
            __html: `Вы действительно хотите удалить <em>номер</em>
                                                <strong>${number} ${region}</strong> из списка
                                                                    транспортных средств?`
          };
        }
      },
      errors: {
        notEmpty: 'Это поле не должно быть пустым',
        format: 'Гос. номер должен быть вида А 000 АА 00 или А000АА000',
        occupied: 'Это транспортное средство уже в списке',
        parked: 'Это транспортное средство уже припарковано',
        actionError: 'Не удалось выполнить действие',
        maxLength: 'Максимальная длинна номер - 9 символов'
      },
      labels: {
        name: 'Имя (Не обязательно)',
        registrationNumber: 'Регистрационный номер'
      }
    }
  },
  documents: {
    title: 'Документы',
    emptyList: 'Список документов пока пуст',

    edit: {
      addDoc: {
        title: 'Добавить документ'
      },
      editDoc: {
        title: 'Редактировать документ'
      },
      deleteDoс: {
        title: 'Удалить документ',
        message: (typeLabel, title) => {
          return {
            __html: `Вы действительно хотите удалить <em>"${typeLabel}" </em>
                        <strong>${title}</strong> из списка
                        документов?`
          };
        }
      },
      errors: {
        notEmpty: 'Это поле обязательно для заполнения',
        docType: 'Необходимо выбрать тип документа',
        stsFormat: 'СТС должен быть формата 00 XX 000000',
        dlFormat: 'ВУ должно быть формата 00 XX 000000 или 00 00 000000',
        occupied: 'Такой документ уже существует',
        validError: 'Невозможно валидировать это поле'
      }
    },
    sertificates: {
      driverLicense: 'Водительское удостоверение (ВУ)',
      vehicleCertificate: 'Свидетельство о регистрации транспортного средства (СТС)'
    },
    labels: {
      documentType: 'Тип документа',
      seriesAndNumber: 'Серия и номер документа'
    }
  },
  balance: {
    title: 'Баланс',
    message: 'Ваш баланс',
    currency: {
      one: 'рубль',
      two: 'рубля',
      three: 'рублей'
    }
  },

  error: {
    loadError: 'Произошла ошибка при загрузке данных.',
    loadProfileError: 'Произошла ошибка при загрузке данных профиля',
    error: 'Ошибка',
    tryAgain: 'Попробовать еще раз'
  },

  exit: {
    title: 'Выход из профиля'
  }
};

export default {
  eng: profileEng,
  ru: profileRu
};
