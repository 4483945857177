import SessionCancelModal from '../../Containers/ProfileContainer/Parking/NoBalance/View/CancelSessionModal';
import confirm from '../../Components/Common/Modals/ConfirmModal';
import ImageModal from '../../Components/Common/Modals/ImageModal';

const modalList = {
  cancelSession: SessionCancelModal,
  confirm,
  image: ImageModal,
};

export default modalList;
