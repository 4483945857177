const loginFormEng = {
  login: 'Log In',
  restorePassword: 'Restore password',
  registration: 'Sign Up',
  password: 'Password',
  entering: 'Entering',
  captcha: 'Code from the image',
  errors: {
    email: 'Invalid email',
    emailPhone: 'Entered incorrect E-mail or phone number',
    emptyField: 'This field is required',
    incorrectLoginOrPassword: 'Invalid login or password',
    error: 'Error',
    loginTryLimit:
      'You entered the password incorrectly several times. Enter the code from the image in the box below',
    CaptchaIncorrect: 'You have entered an incorrect code from the image. try again'
  }
};

const loginFormRu = {
  login: 'Вход',
  registration: 'Регистрация',
  restorePassword: 'Восстановить пароль',
  password: 'Пароль',
  entering: 'Авторизация',
  captcha: 'Код с изображения',
  errors: {
    email: 'Введен некорректный E-mail адрес',
    emailPhone: 'Введен неокорректный E-mail или телефон',
    emptyField: 'Вам необходимо заполнить это поле',
    incorrectLoginOrPassword: 'Неверный логин или пароль',
    error: 'Ошибка',
    loginTryLimit: 'Вы несколько раз неправильно ввели пароль. Введите код с картинки в поле ниже',
    CaptchaIncorrect: 'Вы ввели неверный код с изображения. Попробуйте еще раз'
  }
};

export default {
  eng: loginFormEng,
  ru: loginFormRu
};
