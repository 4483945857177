import React, { Component, Fragment } from 'react';

import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { translate } from 'react-i18next';

import UIStore from '../../../../Stores/UIStore';
import PathStore from '../../../../Stores/PathStore';
import LoginStore from '../../../../Stores/LoginStore';
import ProfileParkingStore from '../../../../Stores/ProfileParkingStore';

import { Row, Col, Button, Modal } from 'reactstrap';
import Input from '../../../../Components/MaterialDesign/Input';
import Loader from '../../../../Components/Loader';
import Captcha from '../../../../Components/Captcha';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';

import faTimes from '@fortawesome/fontawesome-free-solid/faTimes';
import signInAlt from '@fortawesome/fontawesome-free-solid/faSignInAlt';

@translate(['loginForm'], { wait: true })
@observer
class CoopLogin extends Component {
  innInput = React.createRef();
  passwordInput = React.createRef();
  captchaInput = null;

  @observable _formSubmitted = false;
  @observable _isLoading = false;
  @observable UIStore;

  render() {
    const Content = this.modalContent();

    // eslint-disable-next-line
    let f = LoginStore.captchaBase64;
    return (
      <Fragment>
        <Modal
          autoFocus={false}
          isOpen={UIStore.loginModal}
          className="modal-login"
          onClosed={() => (this._isLoading = false)}
        >
          <Content />
        </Modal>
      </Fragment>
    );
  }

  modalContent = () => {
    const { status, t } = this.props;

    if (status !== 'error' && this._isLoading) {
      return () => {
        return (
          <Fragment>
            <span className="loading-title">{t('loginForm:entering')}</span>
            <Loader />
          </Fragment>
        );
      };
    }

    return () => {
      return (
        <Fragment>
          {this.renderHeader()}
          {this.renderBody()}
        </Fragment>
      );
    };
  };

  renderHeader() {
    return (
      <Row className="modal-login__header">
        <Col className="modal-login__enter" xs={10}>
          Войти как юридическое лицо
        </Col>
        <Col className="modal-login__close" xs={2}>
          <FontAwesomeIcon icon={faTimes} onClick={this.handleClose} />
        </Col>
      </Row>
    );
  }

  renderBody() {
    const { t, error, toStandart } = this.props;

    const errorText = this.error(error);
    return (
      <Row noGutters className="justify-content-center align-items-center">
        <Col xs={12} className="login__container">
          <form className="form-horizontal" onSubmit={this.handleSubmit}>
            <fieldset>
              <Row className="login__error justify-content-center">
                <Col xs={11}>{errorText}</Col>
              </Row>
              <Input
                label="ИНН"
                type="text"
                name="INN"
                id="INN"
                ref={this.innInput}
                onBlur={this.handleBlur.bind(this, 'inn')}
              />
              <Input
                label="Номер договора"
                type="password"
                name="pass"
                id="pass-login"
                passwordEye
                ref={this.passwordInput}
                onBlur={this.handleBlur.bind(this, 'pass')}
              />
              {LoginStore.captchaBase64 ? (
                <Row>
                  <Col>
                    <Row>
                      <Col style={{ display: 'flex', justifyContent: 'center' }}>
                        <Captcha value={LoginStore.captchaBase64} />
                      </Col>
                    </Row>
                    <Row>
                      <Col style={{ display: 'flex', justifyContent: 'center' }}>
                        <Input
                          label={t('loginForm:captcha')}
                          type="captcha"
                          name="captcha"
                          onChange={event => {
                            this.captchaInput = event.target.value;
                          }}
                          id="captcha"
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ) : null}
              <div className=" modal-login__footer ">
                <div className="modal-login__footer-inner">
                  <Button
                    type="submit"
                    color="primary"
                    className="btn-small-round modal-login__submit"
                  >
                    {this.buttonIcon()}
                    <span className="modal-login__submit_inner">{t('loginForm:login')}</span>
                  </Button>
                  <span
                    className="btn-registration login__back-to-registration"
                    style={{ backgroundColor: 'none' }}
                    onClick={toStandart}
                  >
                    Назад
                  </span>
                </div>
              </div>
            </fieldset>
          </form>
        </Col>
      </Row>
    );
  }

  buttonIcon() {
    return <FontAwesomeIcon icon={signInAlt} />;
  }

  handleClose = (withReset = true) => {
    const { onClose } = this.props;

    LoginStore.reset();
    if (withReset) {
      ProfileParkingStore.setDefaultZoneNumber(null);
    }
    UIStore.toggleLoginModal();
    this._isLoading = false;
    onClose();
  };

  handleSubmit = async event => {
    event.preventDefault();

    await this.validateFields();
  };

  validateFields = async () => {
    let globalStatement = true;
    const refs = {
      password: this.passwordInput,
      inn: this.innInput
    };

    for (const field in refs) {
      const statement = this.validateField(refs[field].current.name, refs[field].current.value);
      globalStatement = globalStatement && statement.success;
      refs[field].current.setErrorState(statement.success, statement.error);
    }

    if (globalStatement) {
      this.submit();
    }
  };

  submit = async () => {
    const { setTokenToCoopUser, coopLogIn, history } = this.props;

    const inn = this.innInput.current.value.trim();
    const password = this.passwordInput.current.value.trim();

    this._isLoading = true;

    this._formSubmitted = true;
    const token = await coopLogIn(inn, password, this.captchaInput);

    this.captchaInput = null;
    this._formSubmitted = false;

    if (token) {
      setTokenToCoopUser(token);

      this.handleClose(false);
      return;
    }

    if (this.props.status === LoginStore.errorMessages.emptyPass) {
      const path = `/restore-password?error=${LoginStore.errorMessages.emptyPass}`;

      this.handleClose();
      history.push(PathStore.pathWithLang(path));
    }
  };

  handleBlur = name => {
    let field = null;
    let value = null;

    switch (name) {
      case 'inn':
        field = this.innInput.current;
        break;
      case 'pass':
        field = this.passwordInput.current;
        break;
      default:
        return;
    }

    value = field.value !== null ? String(field.value).trim() : null;

    const statement = this.validateField(field, value);

    field.setErrorState(statement.success, statement.error);
  };

  validateField(field, value) {
    const { t } = this.props;
    if (!this._valueIsNotEmpty(value)) {
      return this._createStatement(false, t('loginForm:errors:emptyField'));
    } else {
      return this._createStatement(true);
    }
  }

  _valueIsNotEmpty = value => {
    return value !== null && typeof value !== 'undefined' && String(value).trim().length > 0;
  };

  _createStatement = (success = true, error) => {
    return {
      success,
      error
    };
  };

  error(error) {
    const { t } = this.props;
    switch (error) {
      case 'Request error':
        return <div className="alert alert-danger">{t('loginForm:errors:error')}</div>;
      case 'UnregistredAccount':
        return (
          <div className="alert alert-danger">{t('loginForm:errors:incorrectLoginOrPassword')}</div>
        );
      case 'LoginTryLimit':
        return <div className="alert alert-danger">{t('loginForm:errors:loginTryLimit')}</div>;
      case 'CaptchaIncorrect':
        return <div className="alert alert-danger">{t('loginForm:errors:CaptchaIncorrect')}</div>;
      case 'error':
        return (
          <div className="alert alert-danger">{t('loginForm:errors:incorrectLoginOrPassword')}</div>
        );
      default:
        return null;
    }
  }

  _bindRefs = ref => {
    this._refs.push(ref);
  };
}

export default CoopLogin;
