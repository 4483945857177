import React, { Component, Fragment } from 'react';

import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import spinner from '@fortawesome/fontawesome-free-solid/faSpinner';
import PropTypes from 'prop-types';

import { translate } from 'react-i18next';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';

import Refill from './Refill';
import Subscription from './Subscription';
import Start from './Start';
import Renew from './Renew';

@translate(['parking'], { wait: true })
export default class Payment extends Component {
  constructor(props) {
    super(props);
    this.t = props.t;
  }

  render() {
    const { isOpen } = this.props;
    return (
      <Modal isOpen={isOpen} className="payment-component" size="lg">
        <ModalHeader>{this._renderModalHeader()}</ModalHeader>
        <ModalBody>{this._renderModalBody()}</ModalBody>
      </Modal>
    );
  }

  _renderModalHeader = () => {
    switch (this.props.actionType) {
      case 'start':
        return this.t('parking:currentParkings:paymentTitle');

      case 'renew':
        return this.t('parking:currentParkings:renewTitle');

      case 'refill':
        return this.t('parking:currentParkings:refill');

      case 'subscription':
        return this.t('operations:buySubscription');

      default:
        return this.t('parking:currentParkings:replenishment');
    }
  };

  _renderModalBody = () => {
    const { onSubmit, onExit } = this.props;
    const {
      costData,
      data: {
        cost,
        codeSubscription,
        typeSubscription,
        duration,
        transport,
        parkingCode,
        id,
        endDate,
        zone,
        postPaymentStartTime,
      },
    } = this.props;

    switch (this.props.actionType) {
      case 'start':
        return (
          <Start
            duration={duration}
            transport={transport}
            sale={costData && costData.totaldiscount}
            parkingCode={parkingCode}
            postPaymentStartTime={postPaymentStartTime}
            zone={zone}
            price={costData && costData.totalcost}
            amountToPay={costData && costData.refill}
            onSubmit={onSubmit}
            onAbort={onExit}
          />
        );

      case 'renew':
        return (
          <Renew
            reservation={id}
            timestart={endDate}
            transport={transport}
            zone={zone}
            parkingCode={parkingCode}
            onSubmit={onSubmit}
            onAbort={onExit}
          />
        );

      case 'subscription':
        return (
          <Subscription
            price={cost}
            codeSubscription={codeSubscription}
            typeSubscription={typeSubscription}
            onSubmit={onSubmit}
            onAbort={onExit}
          />
        );

      default:
        return <Refill onSubmit={onSubmit} onAbort={onExit} />;
    }
  };

  static propTypes = {
    isOpen: PropTypes.bool,
    actionType: PropTypes.oneOf(['start', 'renew', 'refill', 'subscription']),

    data: PropTypes.oneOfType([
      PropTypes.shape({
        transport: PropTypes.string,
        zone: PropTypes.shape({
          name: PropTypes.string,
          price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),

        endDate: PropTypes.any,
        sale: PropTypes.number,
        duration: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),

      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        transport: PropTypes.string,
        sale: PropTypes.number,
        zone: PropTypes.shape({
          name: PropTypes.string,
          price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),

        reservation: PropTypes.string,
      }),
    ]),

    onExit: PropTypes.func,
    onSubmit: PropTypes.func,
  };

  static defaultProps = {
    isOpen: false,
    actionType: 'start',
    startDisabled: true,
    data: {
      id: 0,
      transport: null,
      sale: 0,
      duration: 0,

      zone: {
        name: null,
        price: 50,
      },
    },

    onExit: () => null,
    onSubmit: () => null,
  };
}
