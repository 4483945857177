const filterEng = {
  filter: 'Filter',
  parking: 'Parking',
  placesAll: 'Spaces',
  placesFree: 'Vacant spaces',
  categories: {
    roadside: 'On-street parking',
    multilevel: 'Multi-level parking',
    planar: 'Off-street parking',
    social: 'Social parking',
    hotel: 'Hotel',
    pocket: 'Angle parking',
    parkomat: 'Parking meters',
    qiwi: 'Qiwi-terminals',
    infoTable: 'Information table',
    paymentTerminal: 'Payment terminal',
    free: 'Free',
    commerce: 'Paid',
    forDisabled: 'Handicapped parking'
  },
  types: {
    public: 'Public',
    other: 'Others'
  }
};
const filterRu = {
  filter: 'Фильтр',
  parking: 'Парковок',
  placesAll: 'Всего мест',
  placesFree: 'Свободных мест',
  categories: {
    roadside: 'Придорожные',
    multilevel: 'Многоуровневая',
    planar: 'Плоскостные',
    social: 'Социальные',
    hotel: 'Гостиницы',
    pocket: 'Карманы',
    parkomat: 'Паркоматы',
    qiwi: 'Qiwi-терминалы',
    infoTable: 'Информационные табло',
    paymentTerminal: 'Терминал оплаты',
    free: 'Бесплатные',
    commerce: 'Коммерческие',
    forDisabled: 'Для инвалидов'
  },
  types: {
    public: 'Тип парковки',
    other: 'Прочее'
  }
};

export default {
  eng: filterEng,
  ru: filterRu
};
